// src/reducers/index.js

import { combineReducers } from 'redux';
import someReducer from './someReducer'; // Assurez-vous que ce chemin est correct

const rootReducer = combineReducers({
  someReducer, // Assurez-vous que le nom correspond bien à ce qui est utilisé ailleurs
  // Ajoutez d'autres reducers ici si nécessaire
});

export default rootReducer;
