import React, { useState, useEffect } from 'react';
import {
  Box,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Heading,
  Text,
  VStack,
  List,
  ListItem,
  Link,
  Button,
  useToast,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
} from '@chakra-ui/react';

const apiUrl = process.env.REACT_APP_API_URL; // Récupérer l'URL de base depuis la variable d'environnement

function ModelProfile() {
  const [modelInfo, setModelInfo] = useState(null); // Pas de données initiales
  const [isLoading, setIsLoading] = useState(true); // Indicateur de chargement
  const [isOpen, setIsOpen] = useState(false);
  const cancelRef = React.useRef();
  const toast = useToast();

  // Utilise useEffect pour récupérer les informations personnelles lors du montage du composant
  useEffect(() => {
    const fetchModelInfo = async () => {
      try {
        const modelId = localStorage.getItem('modelId'); // Récupère l'ID modèle du localStorage
        const response = await fetch(`${apiUrl}/api/model/${modelId}`);
        const data = await response.json();

        if (response.ok) {
          setModelInfo(data); // Met à jour l'état avec les informations du modèle
        } else {
          toast({
            title: 'Erreur lors de la récupération des informations.',
            description: data.message || 'Une erreur est survenue.',
            status: 'error',
            duration: 5000,
            isClosable: true,
          });
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des informations personnelles:', error);
        toast({
          title: 'Erreur serveur.',
          description: 'Impossible de récupérer vos informations pour le moment.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      } finally {
        setIsLoading(false); // On indique que le chargement est terminé
      }
    };

    fetchModelInfo(); // Appel de la fonction lors du montage
  }, [toast]); // Inclure `toast` dans le tableau des dépendances

  // Fonction pour ouvrir la boîte de dialogue de suppression
  const openDeleteDialog = () => setIsOpen(true);

  // Fonction pour fermer la boîte de dialogue de suppression
  const closeDeleteDialog = () => setIsOpen(false);

  // Fonction pour supprimer le compte
  const handleDeleteAccount = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/model/delete-account`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: modelInfo.email }), // Supposons qu'on utilise l'email pour identifier le modèle
      });

      const data = await response.json();

      if (response.ok) {
        toast({
          title: 'Compte supprimé.',
          description: 'Votre compte a été supprimé définitivement.',
          status: 'success',
          duration: 5000,
          isClosable: true,
        });

        // Rediriger ou faire quelque chose après la suppression du compte
      } else {
        toast({
          title: 'Erreur lors de la suppression.',
          description: data.message || 'Une erreur est survenue.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    } catch (error) {
      console.error('Erreur lors de la suppression du compte:', error);
      toast({
        title: 'Erreur serveur.',
        description: 'Impossible de supprimer votre compte pour le moment.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    } finally {
      closeDeleteDialog(); // Ferme la boîte de dialogue après la suppression
    }
  };

  return (
    <Box p={8}>
      <Heading mb={6}>Paramètres du modèle</Heading>
      <Tabs variant="soft-rounded" colorScheme="pink">
        <TabList>
          <Tab>Compte</Tab>
          <Tab>Informations</Tab>
        </TabList>

        <TabPanels>
          {/* Onglet COMPTE */}
          <TabPanel>
            <Heading size="md" mb={4}>Informations personnelles</Heading>
            {isLoading ? (
              <Text>Chargement des informations...</Text>
            ) : (
              modelInfo && (
              <VStack align="start" spacing={4}>
        <Text><strong>Nom complet :</strong> {modelInfo.fullName || "Non spécifié"}</Text>
        <Text><strong>Email :</strong> {modelInfo.email || "Non spécifié"}</Text>
        <Text>
          <strong>Adresse :</strong> 
          {modelInfo.address?.addressLine1 && ` ${modelInfo.address.addressLine1},`}
          {modelInfo.address?.addressLine2 && ` ${modelInfo.address.addressLine2},`}
          {modelInfo.address?.city && ` ${modelInfo.address.city},`}
          {modelInfo.address?.postalCode && ` ${modelInfo.address.postalCode},`}
          {modelInfo.address?.country && ` ${modelInfo.address.country}`}
        </Text>
        <Text>
          <strong>Date de naissance :</strong> 
          {modelInfo.dateOfBirth ? new Date(modelInfo.dateOfBirth).toLocaleDateString('fr-FR') : "Non spécifiée"}
        </Text>
      </VStack>
    )
  )}
            <Button
              colorScheme="red"
              mt={6}
              onClick={openDeleteDialog}
            >
              Supprimer mon compte
            </Button>

            {/* Boîte de dialogue de confirmation */}
            <AlertDialog
              isOpen={isOpen}
              leastDestructiveRef={cancelRef}
              onClose={closeDeleteDialog}
            >
              <AlertDialogOverlay>
                <AlertDialogContent>
                  <AlertDialogHeader fontSize="lg" fontWeight="bold">
                    Supprimer définitivement le compte
                  </AlertDialogHeader>

                  <AlertDialogBody>
                    Êtes-vous sûr de vouloir supprimer définitivement votre compte ? Cette action est irréversible.
                  </AlertDialogBody>

                  <AlertDialogFooter>
                    <Button ref={cancelRef} onClick={closeDeleteDialog}>
                      Annuler
                    </Button>
                    <Button colorScheme="red" onClick={handleDeleteAccount} ml={3}>
                      Supprimer
                    </Button>
                  </AlertDialogFooter>
                </AlertDialogContent>
              </AlertDialogOverlay>
            </AlertDialog>
          </TabPanel>

          {/* Onglet INFORMATIONS */}
          <TabPanel>
            <Heading size="md" mb={4}>Informations</Heading>
            <List spacing={3}>
              <ListItem>
                <Link href="/terms-of-service" color="teal.500">Conditions générales de vente</Link>
              </ListItem>
              <ListItem>
                <Link href="/cookies" color="teal.500">Cookies</Link>
              </ListItem>
              <ListItem>
                <Link href="/legal" color="teal.500">Mentions légales</Link>
              </ListItem>
              <ListItem>
                <Link href="/privacy" color="teal.500">Confidentialité</Link>
              </ListItem>
              <ListItem>
                <Link href="/child-protection" color="teal.500">Protection de l'enfance</Link>
              </ListItem>
              <ListItem>
                <Link href="/anti-exploitation" color="teal.500">Politique anti-exploitation</Link>
              </ListItem>
              <ListItem>
                <Link href="/content-policy" color="teal.500">Politique des contenus</Link>
              </ListItem>
            </List>
          </TabPanel>
        </TabPanels>
      </Tabs>
    </Box>
  );
}

export default ModelProfile;
