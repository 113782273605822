import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast, Button, Input, Box, Heading, Text } from '@chakra-ui/react'; // Importer les composants Chakra et le toast

function ForgotPasswordPageUser() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate(); // Pour la redirection après la soumission
  const toast = useToast(); // Initialiser useToast

  const apiUrl = process.env.REACT_APP_API_URL; // Utilisation de la variable d'environnement pour l'API

  const handleForgotPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/api/user/forgot-password-user`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        // Utiliser un toast pour informer l'utilisateur au lieu d'un alert
        toast({
          title: 'Lien de réinitialisation envoyé.',
          description: 'Un lien a été envoyé à votre email pour réinitialiser votre mot de passe et certicode.',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
        // Redirection vers la page de connexion
        navigate('/login');
    } else {
      // Gérer les erreurs spécifiques
      if (response.status === 429) {
        // Cas limiteur (Trop de requêtes)
        toast({
          title: 'Trop de demandes.',
          description: data.message || 'Vous avez atteint la limite de tentatives. Veuillez réessayer plus tard.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      } else {
        // Utiliser un toast pour afficher l'erreur
        toast({
          title: 'Erreur.',
          description: data.message || 'Erreur lors de la demande de réinitialisation.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      }
    }
    } catch (error) {
      console.error('Erreur lors de la demande de réinitialisation:', error);
      // Utiliser un toast pour afficher une erreur serveur
      toast({
        title: 'Erreur serveur.',
        description: 'Une erreur s\'est produite lors de la demande. Veuillez réessayer.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      minHeight="100vh"
      bgGradient="linear(to-r, #FF7E5F, #FEB47B)" // Fond dégradé similaire
    >
      <Box
        as="form"
        onSubmit={handleForgotPassword}
        p="6"
        rounded="lg"
        boxShadow="0 0 20px rgba(0, 128, 255, 0.5)" // Ombre néon bleu/cyan initiale
        bg="white"
        width="400px"
        transition="all 0.3s ease" // Transition fluide pour l'effet au survol
        _hover={{
          boxShadow: '0 0 30px rgba(0, 128, 255, 0.8)', // Bordure néon bleu/cyan agrandie au survol
          transform: 'scale(1.05)', // Légère mise à l'échelle au survol
        }}
      >
        <Heading as="h2" mb={4} textAlign="center" color="gray.700" fontWeight="bold">
          Mot de passe et Certicode oubliés
        </Heading>

        <Text mb={4} textAlign="center" color="gray.500">
          Entrez votre adresse email pour recevoir un lien de réinitialisation de mot de passe et certicode.
        </Text>

        <Input
          type="email"
          placeholder="Entrez votre email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          mb={4}
          borderColor="gray.300"
          focusBorderColor="blue.400" // Couleur de focus moderne
          _hover={{ borderColor: 'gray.400' }} // Effet au survol
        />

        <Button 
          type="submit" 
          colorScheme="blue" 
          width="100%" 
          bgGradient="linear(to-r, blue.400, cyan.400)" // Dégradé bleu/cyan sur le bouton
          _hover={{ bgGradient: 'linear(to-r, blue.500, cyan.500)' }} // Effet de survol
          size="lg"
        >
          Envoyer le lien de réinitialisation
        </Button>
      </Box>
    </Box>
  );
}

export default ForgotPasswordPageUser;
