import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Input, InputGroup, InputRightElement, Button, Heading, Box, Text, useToast, Spinner } from '@chakra-ui/react'; // Importer Spinner
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'; // Import des icônes pour l'œil


function ModelLoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [certicode, setCerticode] = useState(''); // Ajout du champ Certicode
  const [isLoading, setIsLoading] = useState(false); // Nouvel état pour le chargement
  const [showPassword, setShowPassword] = useState(false); // État pour afficher ou masquer le mot de passe
  const navigate = useNavigate();
  const toast = useToast(); // Initialisation du toast

  const apiUrl = process.env.REACT_APP_API_URL; // Utiliser l'URL de base via une variable d'environnement

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Activer l'indicateur de chargement

    try {
      const response = await fetch(`${apiUrl}/api/model/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, certicode }), // Inclure Certicode dans la requête
      });

      const data = await response.json();

      if (response.ok) {
        setTimeout(() => {
          setIsLoading(false); // Désactiver l'indicateur de chargement

          // Stocker les informations de connexion dans le localStorage
          localStorage.setItem('modelId', data.model._id); // Stocker l'ID du modèle dans le localStorage
          localStorage.setItem('username', data.model.username); 
          localStorage.setItem('role', 'model'); // Stocker le rôle 'model' dans le localStorage
          
          // Afficher un toast de bienvenue
          toast({
            title: "Connexion réussie ! 🎉",
            description: `Bienvenue, ${data.model.username} !`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });

          // Redirection vers la page d'accueil après connexion réussie
          navigate('/');
        }, 1000); // Ajouter un délai de 1 seconde pour l'effet visuel
      } else {
        setIsLoading(false); // Désactiver l'indicateur de chargement en cas d'erreur
        toast({
          title: "Erreur de connexion",
          description: data.message || 'Erreur lors de la connexion.',
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      setIsLoading(false); // Désactiver l'indicateur de chargement en cas d'erreur
      toast({
        title: "Erreur serveur",
        description: "Une erreur s'est produite lors de la connexion.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      minHeight="100vh"
      bgGradient="linear(to-r, #FF7E5F, #FEB47B)" // Fond dégradé rose/orangé
    >
      <Box
        as="form"
        onSubmit={handleLogin}
        p="6"
        rounded="lg"
        boxShadow="0 0 20px rgba(255, 105, 180, 0.5)" // Ombre néon rose/orangé initiale
        bg="white"
        width="400px"
        transition="all 0.3s ease" // Transition fluide pour l'effet de survol
        _hover={{
          boxShadow: '0 0 30px rgba(255, 105, 180, 0.8)', // Bordure néon rose/orangé agrandie au survol
          transform: 'scale(1.05)', // Légère mise à l'échelle au survol
        }}
      >
        {/* Texte stylisé "Blurimage" ajouté ici */}
        <Heading
          textAlign="center"
          mb={6}
          sx={{
            background: 'linear-gradient(to right, red, orange, yellow, green, indigo, violet)',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
            transition: 'transform 0.2s', // Effet de survol
            _hover: {
              transform: 'scale(1.1)', // Agrandit le texte au survol
            },
          }}
        >
          Onlybox
        </Heading>

        <Heading as="h2" mb={4} textAlign="center" color="gray.700" fontWeight="bold">
         Se connecter en tant que Modèle
        </Heading>
        
        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          mb={3}
          borderColor="gray.300"
          focusBorderColor="pink.400" // Couleur de focus moderne (rose)
          _hover={{ borderColor: 'gray.400' }} // Effet au survol
          disabled={isLoading} // Désactiver le champ en mode chargement
        />
        
        {/* Champ mot de passe avec l'icône d'œil */}
        <InputGroup mb={3}>
          <Input
          type={showPassword ? 'text' : 'password'} // Si showPassword est vrai, montrer le texte, sinon masquer
          placeholder="Mot de passe"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          borderColor="gray.300"
          focusBorderColor="pink.400"
          _hover={{ borderColor: 'gray.400' }}
          disabled={isLoading}
          />
        <InputRightElement width="3rem">
        <Button h="1.75rem" size="sm" onClick={() => setShowPassword(!showPassword)}>
      {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />} {/* Inverser les icônes */}
    </Button>
  </InputRightElement>
</InputGroup>

        <Input
          type="text"
          placeholder="Certicode"
          value={certicode}
          onChange={(e) => setCerticode(e.target.value)}
          mb={3}
          maxLength={6} // Limite à 6 chiffres pour le Certicode
          pattern="\d{6}" // Accepter uniquement 6 chiffres
          inputMode="numeric" // Ouvre le clavier numérique sur mobile
          onKeyPress={(e) => {
            if (!/[0-9]/.test(e.key)) {
              e.preventDefault(); // Empêcher l'entrée de lettres ou autres caractères non numériques
            }
          }}
          borderColor="gray.300"
          focusBorderColor="pink.400" // Couleur de focus moderne (rose)
          _hover={{ borderColor: 'gray.400' }} // Effet au survol
          disabled={isLoading} // Désactiver le champ en mode chargement
        />

        <Button 
          type="submit" 
          colorScheme="pink" 
          width="100%" 
          bgGradient="linear(to-r, pink.400, orange.400)" // Dégradé rose/orangé sur le bouton
          _hover={{ bgGradient: 'linear(to-r, pink.500, orange.500)' }} // Effet de survol
          size="lg"
          disabled={isLoading} // Désactiver le bouton si en mode chargement
        >
          {isLoading ? <Spinner size="sm" /> : 'Se connecter'} {/* Afficher le spinner pendant le chargement */}
        </Button>

        <Text mt={4} textAlign="center">
          <Link to="/forgot-password" style={{ color: 'blue' }}>
            Mot de passe oublié ?
          </Link>
        </Text>
      </Box>
    </Box>
  );
}

export default ModelLoginPage;
