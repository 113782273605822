// src/pages/SignUpPage.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom'; 
import { InputGroup, InputRightElement, Button, Heading, Text, useToast } from '@chakra-ui/react';
import { CheckIcon, CloseIcon } from '@chakra-ui/icons';

const apiUrl = process.env.REACT_APP_API_URL;

const SignUpPage = () => {
  const [formData, setFormData] = useState({
    username: '',
    firstName: '', // Nouveau champ Prénom
    lastName: '',  // Nouveau champ Nom
    addressLine1: '', // Adresse ligne 1
    addressLine2: '', // Adresse ligne 2
    city: '',  // Ville
    postalCode: '', // Code postal
    country: '', // Pays
    email: '',
    confirmEmail: '',
    password: '',
    confirmPassword: '',
    dateOfBirth: '',
    certicode: ''
  });

  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrength, setPasswordStrength] = useState('');
  const [validations, setValidations] = useState({
    username: false,
    firstName: false,       // Validation pour le prénom
    lastName: false,        // Validation pour le nom
    addressLine1: false,    // Validation pour l'adresse (ligne 1)
    city: false,            // Validation pour la ville
    postalCode: false,      // Validation pour le code postal
    country: false,         // Validation pour le pays
    email: false,
    confirmEmail: false,
    password: false,
    confirmPassword: false,
    dateOfBirth: false,
    certicode: false
  });
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);
  const [isPrivacyPolicyAccepted, setIsPrivacyPolicyAccepted] = useState(false);
  
  const navigate = useNavigate(); 
  const toast = useToast(); 

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({ ...formData, [name]: value });

    // Validation dynamique pour chaque champ
    if (name === 'username') {
      setValidations((prev) => ({ ...prev, username: value.length > 0 }));
    }

    if (name === 'firstName' || name === 'lastName') {
      setValidations((prev) => ({ ...prev, [name]: value.trim().length > 0 }));
    }
    if (name === 'addressLine1' || name === 'city' || name === 'postalCode' || name === 'country') {
      setValidations((prev) => ({ ...prev, [name]: value.trim().length > 0 }));
    }

    if (name === 'postalCode') {
      const isPostalCodeValid = /^\d{4,5}$/.test(value); // Valider un code postal français ou belge
      setValidations((prev) => ({ ...prev, postalCode: isPostalCodeValid }));
    }

    if (name === 'email') {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      const isEmailValid = emailRegex.test(value);
      setValidations((prev) => ({ ...prev, email: isEmailValid }));
    }

    if (name === 'confirmEmail') {
      setValidations((prev) => ({ ...prev, confirmEmail: value === formData.email }));
    }

    if (name === 'dateOfBirth') {
      // Ajouter une validation pour s'assurer que l'année est bien de 4 chiffres
      const regexDate = /^\d{4}-\d{2}-\d{2}$/; // Format AAAA-MM-JJ
      const isValidDate = regexDate.test(value);
      if (!isValidDate) {
        setValidations((prev) => ({ ...prev, dateOfBirth: false }));
      } else {
        const birthDate = new Date(value);
        const age = new Date().getFullYear() - birthDate.getFullYear();
        setValidations((prev) => ({ ...prev, dateOfBirth: age >= 18 })); // Valide seulement si l'âge est >= 18
      }
    }

    if (name === 'password') {
      let strength = '';
      const passwordValid = value.length >= 8 && value.length <= 64 &&
        /[a-z]/.test(value) && /[A-Z]/.test(value) &&
        /\d/.test(value) && /[^a-zA-Z0-9]/.test(value);
        
      if (passwordValid) {
        strength = 'Fort';
        setValidations((prev) => ({ ...prev, password: true }));
      } else {
        strength = 'Faible';
        setValidations((prev) => ({ ...prev, password: false }));
      }
      setPasswordStrength(strength);
    }

    if (name === 'confirmPassword') {
      setValidations((prev) => ({ ...prev, confirmPassword: value === formData.password }));
    }

    if (name === 'certicode') {
      const isCerticodeValid = /^\d{6}$/.test(value);
      setValidations((prev) => ({ ...prev, certicode: isCerticodeValid }));
    }
  };

  

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (
      !validations.username ||
      !validations.firstName ||
      !validations.lastName ||
      !validations.addressLine1 ||
      !validations.city ||
      !validations.postalCode ||
      !validations.country ||
      !validations.email ||
      !validations.confirmEmail ||
      !validations.password ||
      !validations.confirmPassword ||
      !validations.dateOfBirth ||
      !validations.certicode
    ) {
      toast({
        title: "Champs incorrects",
        description: "Veuillez vérifier les champs incorrects.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    if (!isTermsAccepted || !isPrivacyPolicyAccepted) {
      toast({
        title: "Conditions non acceptées",
        description: "Veuillez accepter les conditions générales et la politique de confidentialité.",
        status: "warning",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
      return;
    }

    // Calculer si l'utilisateur est majeur
   const birthDate = new Date(formData.dateOfBirth);
   const age = new Date().getFullYear() - birthDate.getFullYear();
   const isAdultConfirmed = age >= 18; // Utilisateur majeur si âge >= 18

    try {
      const response = await fetch(`${apiUrl}/api/user/signup`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        ...formData,
        isAdultConfirmed, // Conservez la vérification de la majorité
        firstName: formData.firstName,
        lastName: formData.lastName,
        address: {
          addressLine1: formData.addressLine1,
          addressLine2: formData.addressLine2,
          city: formData.city,
          postalCode: formData.postalCode,
          country: formData.country
        }
      })
    });

      const data = await response.json();

      if (response.ok) {
        // Ne pas connecter l'utilisateur automatiquement
        toast({
          title: "Inscription réussie ! 🎉",
          description: "Vérifiez vos e-mails pour activer votre compte et commencer l’aventure.!",
          status: "success",
          duration: 5000,
          isClosable: true,
          position: "top",
          icon: <CheckIcon color="white" boxSize={6} />, 
        });
        // Naviguer vers une page indiquant que la confirmation est nécessaire
        navigate('/email-confirmation-sent'); 
      } else {
        toast({
          title: "Erreur d'inscription",
          description: data.message,
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    } catch (error) {
      toast({
        title: "Erreur serveur",
        description: "Une erreur s'est produite lors de l’inscription.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  const toggleShowPassword = () => setShowPassword(!showPassword); 

  const renderValidationIcon = (isValid) => {
    return isValid ? <CheckIcon color="green.500" /> : <CloseIcon color="red.500" />;
  };

  const dateInputStyle = {
    WebkitAppearance: 'none',
    MozAppearance: 'none',
    appearance: 'none',
    border: '1px solid #ccc',
    padding: '10px',
    backgroundColor: '#f9f9f9', 
    borderRadius: '4px',
  };

  return (
    <div style={{ padding: '20px', maxWidth: '500px', margin: 'auto' }}>
      <Heading as="h2" mb={4} textAlign="center" color="gray.600">
        Inscris-toi 🚀
      </Heading>
      <Text mb={6} textAlign="center">
        Renseigne tes informations pour t'inscrire. Un mail de confirmation te sera envoyé.
      </Text>

      <form onSubmit={handleSubmit}>
      <InputGroup>
        <input type="text" name="username" placeholder="Nom d'utilisateur" onChange={handleChange } />
        <InputRightElement>{renderValidationIcon(validations.username)}</InputRightElement>
      </InputGroup>
      {/* Message d'erreur pour le nom d'utilisateur */}
{!validations.username && formData.username && (
  <Text color="red.500">Le nom d'utilisateur est obligatoire.</Text>
)}

      {/* Nouveau champ pour le nom complet */}
      <InputGroup>
    <input
    type="text"
    name="firstName"
    placeholder="Prénom"
    onChange={handleChange}
   />
  <InputRightElement>{renderValidationIcon(validations.firstName)}</InputRightElement>
  </InputGroup>
  {/* Message d'erreur pour le prénom */}
{!validations.firstName && formData.firstName && (
  <Text color="red.500">Le prénom est obligatoire.</Text>
)}

  <InputGroup>
  <input
    type="text"
    name="lastName"
    placeholder="Nom"
    onChange={handleChange}
  />
  <InputRightElement>{renderValidationIcon(validations.lastName)}</InputRightElement>
</InputGroup>
{/* Message d'erreur pour le nom */}
{!validations.lastName && formData.lastName && (
  <Text color="red.500">Le nom est obligatoire.</Text>
)}


<InputGroup>
  <input
    type="text"
    name="addressLine1"
    placeholder="Adresse (ligne 1)"
    onChange={handleChange}
  />
  <InputRightElement>{renderValidationIcon(validations.addressLine1)}</InputRightElement>
</InputGroup>
{/* Message d'erreur pour l'adresse */}
{!validations.addressLine1 && formData.addressLine1 && (
  <Text color="red.500">L'adresse est obligatoire.</Text>
)}

<InputGroup>
  <input
    type="text"
    name="addressLine2"
    placeholder="Adresse (ligne 2 - optionnel)"
    onChange={handleChange}
  />
</InputGroup>

<InputGroup>
  <input
    type="text"
    name="city"
    placeholder="Ville"
    onChange={handleChange}
  />
  <InputRightElement>{renderValidationIcon(validations.city)}</InputRightElement>
</InputGroup>
{/* Message d'erreur pour la ville */}
{!validations.city && formData.city && (
  <Text color="red.500">La ville est invalide ou contient des mots interdits.</Text>
)}

<InputGroup>
  <input
    type="text"
    name="postalCode"
    placeholder="Code postal"
    onChange={handleChange}
  />
  <InputRightElement>{renderValidationIcon(validations.postalCode)}</InputRightElement>
  </InputGroup>
  {/* Message d'erreur pour le code postal */}
{!validations.postalCode && formData.postalCode && (
  <Text color="red.500">Le code postal doit contenir 4 ou 5 chiffres.</Text>
)}

  <InputGroup>
  <select
    name="country"
    onChange={handleChange}
    >
    <option value="">Sélectionnez votre pays</option>
    <option value="France">France</option>
    <option value="Belgique">Belgique</option>
    <option value="Suisse">Suisse</option>
    <option value="Canada">Canada</option>
    <option value="Autre">Autre</option>
    </select>
    <InputRightElement>{renderValidationIcon(validations.country)}</InputRightElement>
    </InputGroup>
    {/* Message d'erreur pour le pays */}
{!validations.country && formData.country && (
  <Text color="red.500">Le pays est obligatoire.</Text>
)}

        <label htmlFor="dateOfBirth" style={{ marginBottom: '8px' }}>
        Date de naissance (cliquez pour sélectionner)
        </label>

      <InputGroup>
        <input 
        type="date" 
        name="dateOfBirth" 
        placeholder="JJ/MM/AAAA"
        onChange={handleChange} 
        style={dateInputStyle}  // Appliquer les styles ici
        max="9999-12-31" // Restriction de la date maximale
        />
        <InputRightElement>{renderValidationIcon(validations.dateOfBirth)}</InputRightElement>
      </InputGroup>
      {/* Message d'erreur pour la date de naissance */}
{!validations.dateOfBirth && formData.dateOfBirth && (
  <Text color="red.500">Vous devez avoir au moins 18 ans.</Text>
)}

        <InputGroup>
          <input type="email" name="email" placeholder="Email" onChange={handleChange} />
          <InputRightElement>{renderValidationIcon(validations.email)}</InputRightElement>
        </InputGroup>
        {/* Message d'erreur pour l'email */}
{!validations.email && formData.email && (
  <Text color="red.500">L'email est invalide.</Text>
)}

        <InputGroup>
          <input type="email" name="confirmEmail" placeholder="Confirme ton adresse email" onChange={handleChange} />
          <InputRightElement>{renderValidationIcon(validations.confirmEmail)}</InputRightElement>
        </InputGroup>
        {/* Message d'erreur pour la confirmation d'email */}
{!validations.confirmEmail && formData.confirmEmail && (
  <Text color="red.500">Les emails ne correspondent pas.</Text>
)}

        <InputGroup>
  <input
    type={showPassword ? 'text' : 'password'}
    name="password"
    placeholder="Mot de passe"
    onChange={handleChange}
  />
  <InputRightElement>
    <Button onClick={toggleShowPassword}>
      {showPassword ? '👁️' : '👁️‍🗨️'}
    </Button>
  </InputRightElement>
</InputGroup>

{/* Message d'erreur pour le mot de passe */}
{!validations.password && formData.password && (
  <Text color="red.500">Le mot de passe est invalide. Assurez-vous de respecter les exigences.</Text>
)}

        {/* Indicateur visuel pour les critères du mot de passe */}
        <div style={{ color: passwordStrength === 'Fort' ? 'green' : '#666', fontSize: '14px', marginTop: '8px' }}>
          <ul style={{ listStyleType: 'none', paddingLeft: '0' }}>
            <li style={{ textDecoration: formData.password.length >= 8 && formData.password.length <= 64 ? 'line-through' : 'none' }}>
              <span style={{ color: formData.password.length >= 8 ? 'green' : 'gray' }}>• 8 à 64 caractères</span>
            </li>
            <li style={{ textDecoration: /[a-z]/.test(formData.password) ? 'line-through' : 'none' }}>
              <span style={{ color: /[a-z]/.test(formData.password) ? 'green' : 'gray' }}>• Inclure une lettre minuscule</span>
            </li>
            <li style={{ textDecoration: /[A-Z]/.test(formData.password) ? 'line-through' : 'none' }}>
              <span style={{ color: /[A-Z]/.test(formData.password) ? 'green' : 'gray' }}>• Inclure une lettre majuscule</span>
            </li>
            <li style={{ textDecoration: /\d/.test(formData.password) ? 'line-through' : 'none' }}>
              <span style={{ color: /\d/.test(formData.password) ? 'green' : 'gray' }}>• Inclure un chiffre</span>
            </li>
            <li style={{ textDecoration: /[^a-zA-Z0-9]/.test(formData.password) ? 'line-through' : 'none' }}>
              <span style={{ color: /[^a-zA-Z0-9]/.test(formData.password) ? 'green' : 'gray' }}>• Inclure un caractère spécial</span>
            </li>
          </ul>
        </div>

        {!validations.password && formData.password && (
          <Text color="red.500">Votre mot de passe doit répondre à toutes les exigences ci-dessus.</Text>
        )}

        <div className={`password-strength ${passwordStrength.toLowerCase()}`}>
          Niveau de sécurité : {passwordStrength}
        </div>

        <InputGroup>
          <input
            type={showPassword ? 'text' : 'password'}
            name="confirmPassword"
            placeholder="Confirme ton mot de passe"
            onChange={handleChange}
          />
          <InputRightElement>{renderValidationIcon(validations.confirmPassword)}</InputRightElement>
        </InputGroup>

        <InputGroup>
          <input
            type="text"
            name="certicode"
            placeholder="Certicode (Code personnel)"
            onChange={handleChange}
            maxLength={6}
            pattern="\d{6}" // Accepter uniquement 6 chiffres
            inputMode="numeric" // Ouvre le clavier numérique sur les appareils mobiles
            onKeyPress={(e) => {
              if (!/[0-9]/.test(e.key)) {
                e.preventDefault(); // Empêche l'entrée de lettres ou de caractères non numériques
              }
            }}
          />
          <InputRightElement>{renderValidationIcon(validations.certicode)}</InputRightElement>
        </InputGroup>

        <div>
          <label>
            <input
              type="checkbox"
              checked={isTermsAccepted}
              onChange={(e) => setIsTermsAccepted(e.target.checked)}
            />
            En t'inscrivant, tu déclares avoir lu et accepté les <a href="/terms">conditions générales</a>.
          </label>
        </div>

        <div>
          <label>
            <input
              type="checkbox"
              checked={isPrivacyPolicyAccepted}
              onChange={(e) => setIsPrivacyPolicyAccepted(e.target.checked)}
            />
            En t'inscrivant, tu déclares avoir lu et accepté la <a href="/privacy">politique de confidentialité</a>.
          </label>
        </div>

        <button type="submit" style={{ backgroundColor: '#28a745', color: 'white', padding: '10px', borderRadius: '5px', border: 'none', marginTop: '10px' }}>
          S'inscrire
        </button>
      </form>
    </div>
  );
};

export default SignUpPage;