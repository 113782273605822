// src/store.js
import { configureStore } from '@reduxjs/toolkit';
// Importe tes reducers ici, par exemple:
import rootReducer from './reducers'; // Assurez-vous d'avoir défini un réducteur root

const store = configureStore({
  reducer: rootReducer,
});

export default store;
