import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast } from '@chakra-ui/react';

const apiUrl = process.env.REACT_APP_API_URL;

const ConfirmEmailPage = () => {
  const navigate = useNavigate();
  const toast = useToast();
  
  useEffect(() => {
    const confirmAccount = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('token');

      if (!token) {
        toast({
          title: "Erreur",
          description: "Token de confirmation manquant.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
        return;
      }

      try {
        const response = await fetch(`${apiUrl}/api/user/confirm-email`, { // Utilisation correcte de la variable apiUrl
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({ token })
        });

        const data = await response.json();

        if (response.ok) {
          toast({
            title: "Email confirmé",
            description: "Votre compte a été activé avec succès. Vous pouvez maintenant vous connecter.",
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
          navigate('/login'); // Redirige vers la page de connexion
        } else {
          toast({
            title: "Erreur de confirmation",
            description: data.message,
            status: "error",
            duration: 5000,
            isClosable: true,
            position: "top",
          });
        }
      } catch (error) {
        toast({
          title: "Erreur serveur",
          description: "Une erreur s'est produite lors de la confirmation.",
          status: "error",
          duration: 5000,
          isClosable: true,
          position: "top",
        });
      }
    };

    confirmAccount();
  }, [toast, navigate]);

  return (
    <div>
      <h1>Confirmation en cours...</h1>
    </div>
  );
};

export default ConfirmEmailPage;