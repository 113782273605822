import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { HamburgerIcon } from '@chakra-ui/icons'; // Importer l'icône hamburger
import { SettingsIcon } from '@chakra-ui/icons';  
import { AiOutlineHeart, AiFillHeart, AiOutlineCheckCircle } from 'react-icons/ai';
import { Tooltip } from '@chakra-ui/react';
import { 
  Box, 
  VStack, 
  Image, 
  Text, 
  Button, 
  Heading, 
  Flex, 
  HStack, 
  Modal, 
  ModalOverlay, 
  ModalContent, 
  ModalHeader, 
  ModalCloseButton, 
  ModalBody, 
  ModalFooter, 
  Circle, 
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  IconButton,
  useBreakpointValue, // Import de useBreakpointValue
  useToast // Import du toast
} from '@chakra-ui/react';
import { motion, AnimatePresence } from 'framer-motion';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick'; // Importer le slider
import '../styles/HomePage.css';
import IndependentBlock from '../components/IndependentBlock';

const categories = ['Bestseller', 'Spécial', 'Nouveautés', 'En création', 'Prochainement'];

const carouselSettings = {
  dots: true, // Affiche les petits points de navigation en bas
  infinite: true,         // Permet le défilement infini
  speed: 500, // Vitesse de transition entre les slides
  slidesToShow: 1,  // Vous pouvez ajuster ici
  slidesToScroll: 1, // Fait défiler une image à la fois
  autoplay: true,         // Active le défilement automatique
  autoplaySpeed: 3000, // Vitesse du défilement automatique (3s)
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 2,   // 2 images sur les écrans de taille moyenne
        slidesToScroll: 1,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,   // 1 image sur les petits écrans
        slidesToScroll: 1,
      },
    },
  ],
};
  
const MotionBox = motion(Box);

const apiUrl = process.env.REACT_APP_API_URL || 'https://onlybox.fans/api';

function HomePage() {
  const [boxes, setBoxes] = useState([]);
  const [username, setUsername] = useState(null);
  const [userRole, setUserRole] = useState(null); // État pour stocker le rôle de l'utilisateur (modèle ou non)
  const [modelId, setModelId] = useState(null); // État pour stocker l'ID du modèle
  const [userId, setUserId] = useState(null);
  const [showMenu, setShowMenu] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [totalCasesOpened, setTotalCasesOpened] = useState(0);
  const [stories, setStories] = useState([]);
  const [carouselImages, setCarouselImages] = useState([]);
  const [selectedStory, setSelectedStory] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [initialOpen, setInitialOpen] = useState(true);
  const maxVisibleStories = useBreakpointValue({ base: 3, md: 5, lg: 7 }); // Nombre de stories visibles basé sur la taille de l'écran (3 pour mobile, 5 pour tablette, 7 pour desktop)
  const [userKeysBalance, setUserKeysBalance] = useState(null); // État pour stocker les clés
  const [likes, setLikes] = useState({}); // État pour stocker les likes des caisses
  const [searchQuery, setSearchQuery] = useState(''); // Nouvel état pour la recherche
  const [filteredBoxes, setFilteredBoxes] = useState([]); // État pour les boîtes filtrées
  const [lastOpenings, setLastOpenings] = useState([]);
  const [userOpenings, setUserOpenings] = useState([]);

  useEffect(() => {
    const storedUserId = localStorage.getItem('userId');
    if (storedUserId) {
      setUserId(storedUserId);
    } else {
      console.warn('Aucun userId trouvé dans le localStorage.');
    }
  }, []);
  
  useEffect(() => {
    const fetchOpenings = async () => {
      try {
        // Fetch pour les ouvertures globales
        const lastOpeningsResponse = await fetch(`${apiUrl}/api/purchase/last-openings`);
        const lastOpeningsData = await lastOpeningsResponse.json();
        if (lastOpeningsData.success) {
          setLastOpenings(lastOpeningsData.lastOpenings);
        } else {
          console.error(lastOpeningsData.message);
        }
  
        // Fetch pour les ouvertures utilisateur
        if (userId) {
          const userOpeningsResponse = await fetch(`${apiUrl}/api/purchase/user-openings/${userId}`);
          const userOpeningsData = await userOpeningsResponse.json();
          if (userOpeningsData.success) {
            setUserOpenings(userOpeningsData.userOpenings);
          } else {
            console.error(userOpeningsData.message);
          }
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des données :', error);
      }
    };
  
    fetchOpenings(); // Appel de la fonction asynchrone
  }, [userId]); // Dépendance à `userId`

  const handleSearchChange = (event) => {
    const query = event.target.value;
    setSearchQuery(query);
  
    const filtered = boxes.filter((box) => 
      box.title.toLowerCase().includes(query.toLowerCase()) || 
      box.description.toLowerCase().includes(query.toLowerCase())
    );
    setFilteredBoxes(filtered);
  };
  
  useEffect(() => {
    setFilteredBoxes(boxes); // Initialiser les boîtes filtrées avec toutes les boîtes au chargement
  }, [boxes]);

  const navigate = useNavigate();
  const toast = useToast(); // Initialisation du toast

  const toggleLike = async (boxId) => {
    const apiUrl = process.env.REACT_APP_API_URL; // Utiliser la variable d'environnement pour l'API
  
    try {
      // Inverser l'état local du like
      const isLiked = !likes[boxId];
      setLikes((prevLikes) => ({
          ...prevLikes,
          [boxId]: isLiked,
      }));

      // Déterminer l'URL de l'API pour liker ou unliker
      const endpoint = isLiked ? '/api/box/like-box' : '/api/box/unlike-box';

      // Effectuer une requête POST pour envoyer le like/unlike au backend
      const response = await fetch(`${apiUrl}${endpoint}`, {
          method: 'POST',
          headers: {
              'Content-Type': 'application/json',
          },
          body: JSON.stringify({
              boxId,
              userId,  // Utiliser l'ID de l'utilisateur connecté
          }),
      });

      const data = await response.json();

      if (response.ok) {
          console.log(`Nombre de likes mis à jour: ${data.likes}`)

          // Afficher un toast pour confirmer l'action
        toast({
          title: "💖 Merci pour votre like !",
          description: "Elle vous sera reconnaissante !",
          status: "success",
          duration: 8000, // Le toast restera affiché pendant 8 secondes
          isClosable: true,
          position: "top",
        });

        // Rafraîchir la page pour mettre à jour l'interface utilisateur
        setTimeout(() => {
          window.location.reload(); // Rafraîchit la page après un délai pour permettre au toast de s'afficher
        }, 1000); // Attends 1 seconde avant de rafraîchir la page pour laisser le toast s'afficher
    } else {
        console.error(`Erreur lors de la mise à jour du like: ${data.message}`);
    }
  } catch (error) {
      console.error('Erreur lors du like/unlike:', error);
  }
};

// Fetch des images du carrousel
useEffect(() => {
  const fetchCarouselImages = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/admin/carousel`);
      const data = await response.json();
      console.log(data); // Afficher les données pour vérifier leur structure
      setCarouselImages(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des images du carrousel:', error);
    }
  };
  fetchCarouselImages();
}, []);

  useEffect(() => {
    const apiUrl = process.env.REACT_APP_API_URL; // Utiliser la variable d'environnement pour l'API

    const fetchKeysBalance = async (userId) => {
      try {
        const response = await fetch(`${apiUrl}/api/user/keys-balance/${userId}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
        const data = await response.json();
        setUserKeysBalance(data.keys_balance); // Mise à jour du solde des clés
      } catch (error) {
        console.error('Erreur lors de la récupération du solde des clés :', error);
      }
    };
  
    // Si l'utilisateur est connecté, récupérer son solde de clés
    if (userId) {
      fetchKeysBalance(userId);
    }

    const fetchBoxes = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/boxes`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
    
        const data = await response.json();
    
        if (!userId) {
          setBoxes(data);
          setFilteredBoxes(data); // Mettez à jour les boîtes filtrées
          return; // Si l'utilisateur n'est pas connecté, on s'arrête ici
        }
    
        // Récupérer les informations de l'utilisateur
        const userResponse = await fetch(`${apiUrl}/api/user/${userId}`, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });
    
        const userData = await userResponse.json();
    
        // Vérifier si l'utilisateur a liké chaque box et mettre à jour l'état des likes
        const likedBoxes = userData.likedBoxes || [];
        const updatedLikes = {};
        likedBoxes.forEach((boxId) => {
          updatedLikes[boxId] = true; // Les boîtes likées par cet utilisateur
        });
    
        setLikes(updatedLikes);
    
        // Vérifiez si l'utilisateur a ouvert chaque boîte
        const updatedBoxes = data.map((box) => ({
          ...box,
          alreadyOpened: box.openedByUsers.includes(userId), // Vérifie si l'utilisateur est dans `openedByUsers`
        }));
    
        // Mettre à jour les boîtes et les boîtes filtrées
        setBoxes(updatedBoxes);
        setFilteredBoxes(updatedBoxes);
      } catch (error) {
        console.error('Erreur lors de la récupération des boîtes :', error);
      }
    };

    const fetchStories = async () => {
      try {
        const response = await fetch(`${apiUrl}/api/admin/stories`);
        const data = await response.json();
        setStories(data);
      } catch (error) {
        console.error('Erreur lors de la récupération des stories:', error);
      }
    };

    const fetchStatistics = async () => {
      try {
        const totalUsersResponse = await fetch(`${apiUrl}/api/total-users`);
        const totalUsersData = await totalUsersResponse.json();
        setTotalUsers(totalUsersData.totalUsers);

        const totalCasesOpenedResponse = await fetch(`${apiUrl}/api/total-cases-opened`);
        const totalCasesOpenedData = await totalCasesOpenedResponse.json();
        setTotalCasesOpened(totalCasesOpenedData.totalCasesOpened);
      } catch (error) {
        console.error('Erreur lors de la récupération des statistiques:', error);
      }
    };

    fetchBoxes();
    fetchStories();
    fetchStatistics();

    const storedUsername = localStorage.getItem('username');
    const storedRole = localStorage.getItem('role');
    const storedModelId = localStorage.getItem('modelId');
    const storedUserId = localStorage.getItem('userId');

    console.log('Nom utilisateur:', storedUsername);
    console.log('Rôle utilisateur:', storedRole);
    console.log('ID du modèle:', storedModelId);
    console.log('ID de l\'utilisateur:', storedUserId); // Vérification de l'ID utilisateur

    setUsername(storedUsername);
    setUserRole(storedRole);
    setModelId(storedModelId);
    setUserId(storedUserId);
  }, [userId]);

  // Fonction pour gérer la déconnexion
  const handleLogout = () => {
    localStorage.clear();
    setUsername(null);
    setUserRole(null);
    setUserId(null);
    setModelId(null);

    // Afficher le toast de déconnexion
    toast({
      title: "👋 Vous nous quittez déjà ?",
      description: "À très bientôt pour de nouvelles surprises !",
      status: "info",
      duration: 3000,
      isClosable: true,
      position: "top"
    });
    
    navigate('/');
  };

  const toggleMenu = () => setShowMenu((prev) => !prev);

  const handleStoryClick = (story) => {
    setSelectedStory(story);
    setIsModalOpen(true);
    setInitialOpen(true);
  };

  const closeModal = () => {
    setSelectedStory(null);
    setIsModalOpen(false);
  };

  const showNextStory = () => {
    const currentIndex = stories.findIndex((s) => s._id === selectedStory._id);
    const nextIndex = (currentIndex + 1) % stories.length;
    setSelectedStory(stories[nextIndex]);
    setInitialOpen(false);
  };

  const showPreviousStory = () => {
    const currentIndex = stories.findIndex((s) => s._id === selectedStory._id);
    const previousIndex = (currentIndex - 1 + stories.length) % stories.length;
    setSelectedStory(stories[previousIndex]);
    setInitialOpen(false);
  };



  return (
    <Box>
      <Box className="background-image"></Box>
      <Box className="content" padding="20px">
        <header className="header">
           <Flex justifyContent="space-between" alignItems="center" width="100%">
            {/* Section gauche : Utilisateurs et caisses */}
             <Flex className="header-left" alignItems="center">
             <Text fontSize="lg" color="white" fontWeight="bold" fontFamily="Roboto">
              👤 {totalUsers} UTILISATEURS
              </Text>
             <Text fontSize="lg" color="white" fontWeight="bold" fontFamily="Roboto">
              📦 {totalCasesOpened} BOXES
              </Text>
              </Flex>
              {/* Section droite : Menu utilisateur */}
              <Flex className="header-right" alignItems="center">
            {username ? (
              <Box className="user-menu">
                <Text onClick={toggleMenu} cursor="pointer"color="purple.300" fontWeight="bold">
                  Bienvenue, {username}!
                </Text>
                {showMenu && (
                  <Box className="dropdown-menu" mt={2}>
                    {userKeysBalance !== null && ( // Affiche le solde des clés uniquement s'il est récupéré
                    <Text fontSize="lg" color="yellow.300" fontWeight="bold" fontFamily="Roboto">
                      🔑 Clés disponibles : {userKeysBalance}</Text>
                    )}
                    {/* Bouton "Inventaire" pour les utilisateurs */}
                    {userRole === 'user' && (
                      <Button onClick={() => navigate('/inventory')} variant="outline" size="sm"color="#FF007F" /* Couleur du texte */>
                        Inventaire
                      </Button>
                     )}
                       {/* Bouton "Achat de clés" uniquement pour les utilisateurs avec le rôle "user" */}
                       {userRole === 'user' && (
                        <Button
                        onClick={() => navigate('/buy-keys')}
                        variant="outline"
                        size="sm"
                        mt={2}
                        color="#FF007F" // Couleur du texte
                        >
                        Achat de clés
                        </Button>
                        )}
                       {userRole === 'model' && modelId && (
                      <Button onClick={() => navigate(`/model/profit/${modelId}`)} 
                      variant="outline" 
                      size="sm" 
                      mt={2}
                      color="#FF007F"
                      >
                        Statistiques
                      </Button>
                        )}
                       <Button
                        onClick={() => {
                        const role = localStorage.getItem('role'); // Récupérer le rôle depuis le localStorage
                        if (role === 'model') {
                        navigate('/model/settings'); // Si c'est un modèle, redirige vers les paramètres du modèle
                        } else {
                        navigate('/settings'); // Sinon, redirige vers les paramètres utilisateur
                        }
                        }}
                        variant="outline"
                        size="sm"
                        mt={2}
                        leftIcon={<SettingsIcon />} // Icône crantée
                        color="#FF007F"
                        >
                       Paramètres
                       </Button>

                    <Button onClick={handleLogout} variant="outline" size="sm" mt={2}
                    color="#FF007F" /* Couleur du texte */>
                      Déconnexion
                    </Button>
                  </Box>
                )}
              </Box>
            ) : (
              // Menu hamburger pour les options de connexion et d'inscription
            <Menu>
            <MenuButton
              as={IconButton}
              icon={<HamburgerIcon />}
              variant="outline"
              aria-label="Options"
              size="lg"
              color="white"
              backgroundColor="transparent"
              _hover={{
                bg: "gray.600", // Couleur de fond au survol
              }}
              _active={{
                bg: "gray.700", // Couleur de fond lors du clic
              }}
            />

            <MenuList
            bg="black"
            border="1px solid"
            borderColor="black"
            boxShadow="lg" // Ombre autour du menu
            borderRadius="md" // Coins arrondis
            animation="ease-in 0.2s" // Animation à l'apparition
            >
            <MenuItem
            onClick={() => navigate('/login')}
           _hover={{
            bg: "black", // Couleur de fond au survol
            color: "gray.700", // Couleur du texte au survol
            }}
            >
            Se connecter
            </MenuItem>
            <MenuItem
            onClick={() => navigate('/signup')}
            _hover={{
            bg: "black",
            color: "gray.700",
            }}
            >
             S'inscrire
            </MenuItem>
            <MenuItem
            onClick={() => navigate('/model/login')}
            _hover={{
            bg: "black",
            color: "#FF007F",
             }}
            >
            Se connecter (Modèle)
            </MenuItem>
            <MenuItem
            onClick={() => navigate('/model/signup')}
           _hover={{
            bg: "black",
            color: "#FF007F",
            }}
            >
            S'inscrire (Modèle)
           </MenuItem>
           </MenuList>
           </Menu>
        )}
      </Flex>
      </Flex>
    </header>

    {/* Ajout de la barre de recherche */}
    <Box className="search-bar-container" marginBottom={5}>
      <input
        type="text"
        placeholder="Taper ici pour rechercher"
        value={searchQuery}
        onChange={handleSearchChange} // La recherche se mettra à jour automatiquement ici
        className="search-bar-input"
      />
    </Box>


     {/* Intégration du carrousel */}
<Box className="carousel-container" marginBottom={5}>
  <Slider {...carouselSettings}>
    {carouselImages.length > 0 && (
      // Ce map parcourt chaque carrouselItem (contenant plusieurs images)
      carouselImages.map((carouselItem) => (
        // Ensuite, nous faisons un deuxième map sur les images individuelles de chaque item
        carouselItem.images.map((image, index) => (
        <Box key={`${carouselItem._id}-${index}`} textAlign="center">
            <Image
              key={index}
              src={`${apiUrl}/uploads/${image}`} // Assurez-vous que le chemin des images est correct
              alt={`Carousel Image ${index + 1}`}  // Utiliser un texte alternatif générique
              objectFit="cover"
              width="100%"
              height="400px" // Hauteur fixe, à ajuster selon votre design
              borderRadius="10px"  // Ajouter des coins arrondis (optionnel)
              margin="0 auto" // Centrer les images
            />
        </Box>
        ))
      ))
    )}
  </Slider>
</Box>

{/* Section Ouvertures en direct */}
<Box className="category-section" mb={5}>
  <IndependentBlock
    lastOpenings={lastOpenings} 
    userOpenings={[]} // On passe une liste vide, car cette section est pour "Ouvertures en direct"
  />
</Box>

{/* Section Vos dernières ouvertures */}
<Box className="category-section" mb={5}>
  <IndependentBlock
    lastOpenings={[]} // On passe une liste vide, car cette section est pour "Vos dernières ouvertures"
    userOpenings={userOpenings} 
  />
</Box>

        <VStack spacing={5} mt={5}>
  <Heading
    sx={{
      background: 'linear-gradient(to right, red, orange, yellow, green, blue, indigo, violet)',
      WebkitBackgroundClip: 'text',
      color: 'transparent',
      transition: 'transform 0.2s', // Effet de survol
      cursor: 'pointer', // Change le curseur pour indiquer que c'est cliquable
      _hover: {
        transform: 'scale(1.1)', // Agrandit le texte au survol
      }
    }}
    onClick={() => window.location.reload()} // Rafraîchit la page
  >
    Onlybox
  </Heading>
  <HStack

  as={motion.div}
  overflowX="auto"  // Active le défilement horizontal
  spacing={4}
  className="stories-container"
  padding={2}
  justifyContent="center"
  width="100%"
  maxWidth="100vw"
  alignItems="center"
  css={{
    '&::-webkit-scrollbar': {
      display: 'none', // Cache la scrollbar sur les appareils modernes
    },
  }}
>
  {stories.slice(0, maxVisibleStories).map((story) => (
    <Box key={story._id} textAlign="center">
      <MotionBox
        whileHover={{ scale: 1.1 }}
        whileTap={{ scale: 0.95 }}
        padding="4px"
        borderRadius="full"
        display="flex"
        alignItems="center"
        justifyContent="center"
        onClick={() => handleStoryClick(story)}
        sx={{
          bgGradient: "linear(to-r, pink.500, orange.500, yellow.500, green.500, blue.500, purple.500)",
          padding: "4px",
          borderRadius: "full",
          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
          overflow: "hidden", // S'assurer que le contenu reste rond
        }}
      >
        <Circle size="72px" bg="white" overflow="hidden">
          <Image
            src={`${apiUrl}/uploads/avatars/${story.avatar}`}
            alt={story.creator}
            boxSize="64px"
            borderRadius="full"
            objectFit="cover"
            opacity={story.viewed ? 0.7 : 1} // Rendre l'image plus terne si visionnée
          />
        </Circle>
      </MotionBox>
      <Text fontSize="sm" mt={2} color="white">
        {story.creator}
      </Text>
    </Box>
  ))}
</HStack>


            <Box className="categories" padding="0">
            {categories.map((category) => (
              <Box key={category} className="category-section" mb={5}>
                <Heading as="h2" size="lg" mb={3} textAlign="left">
                  {category}
                </Heading>
                <Flex className="boxes" id={`category-carousel-${category}`} gap={3} wrap="nowrap" overflowX="auto" paddingX={2}>
                  {filteredBoxes
                    .filter((box) => box.category === category)
                    .map((box) => (
                      <Link to={`/box/${box._id}`} key={box._id} className="box" style={{ marginBottom: '20px' }}>
                        <Image
                          src={`${apiUrl}/uploads/${box.thumbnail}`}
                          alt={`${box.title} thumbnail`}
                          className="box-thumbnail"
                          width="120px"
                          height="100px"
                          objectFit="cover"
                          borderRadius="md"
                          alignItems="flex-start" // assure que les boîtes sont alignées en haut
                          justifyContent="flex-start" // aligne les boîtes à gauche
                        />
                        <Heading as="h3" size="md">
                          {box.title}
                        </Heading>

                        {/* Badge pour indiquer le type de box */}
                        <Flex alignItems="center" mt={2} className="box-type-indicator">
                        {box.boxType === 'photo' && (
                         <Text
                        fontSize="sm"
                        fontWeight="bold"
                        color="blue.400"
                        border="1px solid"
                        borderColor="blue.400"
                        borderRadius="md"
                        padding="2px 8px"
                        >
                        📷 Photos uniquement
                        </Text>
                          )}
                       {box.boxType === 'video' && (
                      <Text
                       fontSize="sm"
                      fontWeight="bold"
                      color="orange.400" // Texte couleur
                      border="1px solid"
                      borderColor="pink.400" // Bordure en rose flashy
                      borderRadius="md"
                       padding="2px 8px"
                      >
                      🎥 Mixte (Photos & Vidéos)
                      </Text>
                       )}
                      </Flex>

                        {/* <Text>Prix HT : {parseFloat(box.priceHT).toFixed(2)}€</Text> */}
                        <Text>Prix TTC : {parseFloat(box.price).toFixed(2)}€</Text>

                        {/* Indicateur pour montrer que la boîte est déjà ouverte */}
                        {box.alreadyOpened && (
                         <Tooltip label="Déjà ouvert" fontSize="sm" hasArrow>
                         <Flex alignItems="center" mt={2} className="indicator">
                        <AiOutlineCheckCircle color="green" size={24} />
                         <Text ml={2} fontSize="sm" color="green.400" display={{ base: "block", md: "none" }}>
                         Déjà ouvert
                         </Text>
                         </Flex>
                         </Tooltip>
                        )}
                       
                        <Flex alignItems="center" mt={2}>
                          {likes[box._id] ? (
                            <AiFillHeart
                            color="red" // Cœur rempli et rouge si la box est likée
                            size={24}
                            cursor="pointer"
                            onClick={(e) => {
                              e.preventDefault();
                              e.stopPropagation();
                              toggleLike(box._id);
                            }}
                            />
                          ) : (
                          <AiOutlineHeart
                          size={24}
                          cursor="pointer"
                          onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            toggleLike(box._id);
                          }}
                          />
                          )}
                          <Text ml={2} fontSize="md" color="white">
                          {box.likes || 0} {/* Affiche le vrai nombre de likes depuis la base de données */}
                          </Text>
                          </Flex>
                      </Link>
                    ))}
                </Flex>
              </Box>
            ))}
          </Box>
        </VStack>
      </Box>

      {selectedStory && (
        <Modal isOpen={isModalOpen} onClose={closeModal}>
          <ModalOverlay />
          <ModalContent>
            <ModalHeader>{selectedStory.title}</ModalHeader>
            <ModalCloseButton />
            <ModalBody>
              <AnimatePresence mode="wait">
                <motion.div
                  key={selectedStory._id}
                  initial={initialOpen ? { opacity: 0, y: 50 } : { opacity: 0, x: 100 }}
                  animate={{ opacity: 1, x: 0, y: 0 }}
                  exit={initialOpen ? { opacity: 0, y: 50 } : { opacity: 0, x: -100 }}
                  transition={{ duration: 0.3 }} // Transition fluide pour les stories suivantes
                  style={{ display: 'flex', height: '100%', cursor: 'pointer' }}
                  onClick={showNextStory}
                >
                  <Box flex="1" onClick={(e) => { e.stopPropagation(); showPreviousStory(); }} style={{ cursor: 'pointer' }}></Box>
                  {/* Condition pour afficher soit une image soit une vidéo */}
                  {selectedStory.media.endsWith('.mp4') ? (
                  <video
                  src={`${apiUrl}/uploads/stories/${selectedStory.media}`}
                  width="100%"
                  controls
                  autoPlay
                  muted
                  loop
                  style={{ borderRadius: '8px', objectFit: 'cover' }}
                  />
                 ) : (
                  <Image
                    src={`${apiUrl}/uploads/stories/${selectedStory.media}`}
                    alt={selectedStory.creator}
                    width="100%"
                    borderRadius="md"
                    objectFit="cover"
                  />
                )}
                  <Box flex="1" onClick={showNextStory} style={{ cursor: 'pointer' }}></Box>
                </motion.div>
              </AnimatePresence>
              <Text mt={2}><strong>Créateur:</strong> {selectedStory.creator}</Text>
            </ModalBody>
            <ModalFooter>
              <Button colorScheme="blue" mr={3} onClick={closeModal}>
                Fermer
              </Button>
            </ModalFooter>
          </ModalContent>
        </Modal>
      )}
    </Box>
  );
}

export default HomePage;