import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Heading,
  VStack,
  StackDivider,
  Badge,
  Flex,
  Stat,
  StatLabel,
  StatNumber,
  Icon,
  Tooltip,
} from '@chakra-ui/react';
import { useParams } from 'react-router-dom'; // Assurez-vous d'avoir installé react-router-dom
import { FaBoxOpen, FaMoneyBillWave } from 'react-icons/fa'; // Ajouter une icône de gains
import { InfoIcon } from '@chakra-ui/icons';

function ModelProfitPage() {
  const { modelId } = useParams();
  const [boxes, setBoxes] = useState([]);
  const [loading, setLoading] = useState(true);

  const apiUrl = process.env.REACT_APP_API_URL; // Récupérer l'URL de base depuis la variable d'environnement

  useEffect(() => {
    const fetchModelBoxes = async () => {
      try {
        // Appeler la première route pour récupérer les caisses associées
        const response = await fetch(`${apiUrl}/api/admin/model/${modelId}/boxes`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        const responseStats = await fetch(`${apiUrl}/api/admin/model/${modelId}/profit`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        });

        if (response.ok && responseStats.ok) {
          const boxData = await response.json();
          const statsData = await responseStats.json();

          // Fusionner les données des caisses avec les statistiques d'ouvertures
          const boxesWithStats = boxData.boxes.map((box) => {
            const correspondingBoxStat = statsData.boxes.find((stat) => stat._id === box._id);
            const openCount = correspondingBoxStat?.openCount || 0;
            const totalRevenue = openCount * box.modelRevenue; // Calcul du revenu total du modèle

            return { ...box, openCount, totalRevenue };
          });

          setBoxes(boxesWithStats); // Mettre à jour les caisses avec les statistiques d'ouvertures et les revenus
        } else {
          setBoxes([]); // Aucune caisse trouvée
        }
      } catch (error) {
        console.error('Erreur lors de la récupération des caisses et des statistiques:', error);
        setBoxes([]); // Gestion des erreurs
      } finally {
        setLoading(false);
      }
    };

    if (modelId) {
      fetchModelBoxes();
    }
  }, [apiUrl, modelId]);

  if (loading) {
    return <Text>Chargement...</Text>;
  }

  if (boxes.length === 0) {
    return (
      <Box textAlign="center" mt={10} p={6} bgGradient="linear(to-r, orange.400, pink.400)" borderRadius="lg" boxShadow="xl">
        <Heading as="h2" mb={4} fontSize="3xl" color="white">
          Statistiques de vos caisses personnelles
        </Heading>
        <Text fontSize="lg" fontWeight="bold" color="white" mb={2}>
          Vous n'avez aucune caisse de créée.
        </Text>
        <Text fontSize="md" color="gray.200">
          Oups ! Aucune caisse à l'horizon... il semblerait que vous n'ayez pas encore de création à votre nom. Pas de
          panique, contactez notre administration via Instagram ou par e-mail pour vous authentifier et débloquer la magie.
        </Text>
      </Box>
    );
  }

  return (
    <Box textAlign="center" mt={10} px={6}>
      <Heading as="h2" mb={8} fontSize="3xl" fontWeight="bold" bgGradient="linear(to-l, #7928CA, #FF0080)" bgClip="text">
        Caisses associées au modèle
      </Heading>
      <VStack
        divider={<StackDivider borderColor="gray.200" />}
        spacing={8}
        align="stretch"
        bg="gray.50"
        borderRadius="lg"
        boxShadow="xl"
        p={8}
      >
        {boxes.map((box) => (
          <Box
            key={box._id}
            borderWidth="1px"
            borderRadius="lg"
            p={6}
            bgGradient="linear(to-r, teal.300, blue.500)"
            color="white"
            boxShadow="2xl"
          >
            <Flex justify="space-between" align="center">
              <Box>
                <Heading as="h3" size="lg" mb={2}>
                  {box.title}
                </Heading>
                <Badge colorScheme="yellow" fontSize="1.1em" mb={2}>
                  Catégorie: {box.category}
                </Badge>
                <Text mt={4} fontSize="md">
                  {box.description}
                </Text>
              </Box>
              <Icon as={FaBoxOpen} boxSize={10} color="yellow.400" />
            </Flex>

            <Flex mt={6} justify="space-between">
              <Stat>
                <StatLabel>Prix TTC</StatLabel>
                <StatNumber>{box.price.toFixed(2)} €</StatNumber> {/* Inverser l'ordre ici */}
              </Stat>
              <Stat>
                <StatLabel>Nombre d'ouvertures</StatLabel>
                <StatNumber>{box.openCount}</StatNumber>
              </Stat>

              {/* Mise en avant du "Total des gains TTC" avec une icône et en gras */}
              <Stat>
                <Flex alignItems="center">
                  <StatLabel fontWeight="bold" fontSize="xl">
                    Total des gains TTC
                  </StatLabel>
                  <Icon as={FaMoneyBillWave} boxSize={6} color="green.400" ml={2} />
                </Flex>
                <StatNumber fontWeight="bold" fontSize="2xl">{box.modelRevenue ? (box.openCount * box.modelRevenue).toFixed(2) : 'Non applicable'} €</StatNumber> {/* Inverser l'ordre ici */}
              </Stat>

              <Stat>
                <StatLabel>Nombre de vues</StatLabel>
                <StatNumber>{box.viewCount}</StatNumber>
              </Stat>
            </Flex>

            <Flex alignItems="center">
              <Text fontSize="sm" color="white" mt={4} fontWeight="bold">
              * Le "Total des gains TTC" représente le montant total que vous percevez pour chaque ouverture de caisse.
              </Text>
              <Tooltip label="Ce montant inclut toutes les taxes. Vous devrez simplement le déclarer comme revenu." fontSize="md">
                <InfoIcon ml={2} color="white" />
              </Tooltip>
            </Flex>
          </Box>
        ))}

        {/* Message de remerciement ajouté ici */}
        <Box mt={1} p={4} bg="blue.500" borderRadius="md" boxShadow="md" color="white">
          <Heading as="h4" size="md" mb={2}>
            Merci pour votre collaboration !
          </Heading>
          <Text fontSize="lg">
            Continuez à faire de superbes créations et à inspirer nos utilisateurs. Votre talent et vos efforts sont très
            appréciés !
          </Text>
        </Box>
      </VStack>
    </Box>
  );
}

export default ModelProfitPage;
