import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { useToast, Button, Input, Box, Heading, Text, Spinner, InputGroup, InputRightElement } from '@chakra-ui/react'; // Importer les composants Chakra UI
import { AiFillEye, AiFillEyeInvisible } from 'react-icons/ai'; // Import des icônes pour l'œil

function LoginPage() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [certicode, setCerticode] = useState(''); // Nouveau champ pour le Certicode
  const [isLoading, setIsLoading] = useState(false); // Nouvel état pour le chargement
  const [showPassword, setShowPassword] = useState(false); // État pour afficher ou masquer le mot de passe
  const navigate = useNavigate();
  const toast = useToast(); // Initialiser useToast

  const apiUrl = process.env.REACT_APP_API_URL; // Utiliser l'URL de base via une variable d'environnement

  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true); // Activer l'indicateur de chargement

    try {
      const response = await fetch(`${apiUrl}/api/user/login`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email, password, certicode }), // Inclure le certicode dans la requête
      });

      const data = await response.json();

      if (response.ok) {
        // Simuler un petit délai pour montrer le spinner
        setTimeout(() => {
          setIsLoading(false); // Désactiver l'indicateur de chargement

          // Stocker les informations de l'utilisateur dans le localStorage
          localStorage.setItem('userId', data.userId);
          localStorage.setItem('username', data.username);
          localStorage.setItem('role', 'user');
          
          // Afficher un toast de bienvenue
          toast({
            title: "👋 Bienvenue !",
            description: `Content de vous revoir, ${data.username} !`,
            status: "success",
            duration: 5000,
            isClosable: true,
            position: "top",
          });

          // Redirige vers la page d'accueil après une connexion réussie
          navigate('/');
        }, 1000); // Ajouter un délai de 1 seconde pour l'effet visuel
      } else {
        setIsLoading(false); // Désactiver l'indicateur de chargement en cas d'erreur
        toast({
          title: "Erreur de connexion",
          description: data.message || "Email, mot de passe ou certicode incorrect.",
          status: "error",
          duration: 5000, // Le toast restera affiché pendant 5 secondes
          isClosable: true,
          position: "top", // Afficher le toast en haut
        });
      }
    } catch (error) {
      setIsLoading(false); // Désactiver l'indicateur de chargement en cas d'erreur
      console.error('Erreur lors de la connexion:', error);
      toast({
        title: "Erreur serveur",
        description: "Une erreur s'est produite lors de la connexion. Veuillez réessayer.",
        status: "error",
        duration: 5000,
        isClosable: true,
        position: "top",
      });
    }
  };

  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      minHeight="100vh"
      bgGradient="linear(to-r, #FF7E5F, #FEB47B)" // Fond dégradé
    >
      <Box
        as="form"
        onSubmit={handleLogin}
        p="6"
        rounded="lg"
        boxShadow="0 0 20px rgba(0, 0, 255, 0.5)" // Ombre néon initiale bleue
        bg="white"
        width="400px"
        transition="all 0.3s ease" // Transition fluide pour l'effet de survol
        _hover={{
          boxShadow: '0 0 30px rgba(0, 0, 255, 0.8)', // Ombre néon bleue agrandie au survol
          transform: 'scale(1.05)', // Légère mise à l'échelle au survol
        }}
      >
        {/* Texte stylisé "Blurimage" ajouté ici */}
        <Heading
          textAlign="center"
          mb={6}
          sx={{
            background: 'linear-gradient(to right, red, orange, yellow, green, indigo, violet)',
            WebkitBackgroundClip: 'text',
            color: 'transparent',
            transition: 'transform 0.2s', // Effet de survol
            _hover: {
              transform: 'scale(1.1)', // Agrandit le texte au survol
            },
          }}
        >
          Onlybox
        </Heading>

        <Heading as="h2" mb={4} textAlign="center" color="gray.700" fontWeight="bold">
         Accédez à votre compte
        </Heading>

        <Input
          type="email"
          placeholder="Email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          mb={3}
          borderColor="gray.300"
          focusBorderColor="blue.400" // Couleur de focus moderne
          _hover={{ borderColor: 'gray.400' }} // Effet au survol
          disabled={isLoading} // Désactiver le champ en mode chargement
        />

        {/* Champ mot de passe avec l'icône d'œil */}
        <InputGroup mb={3}>
          <Input
            type={showPassword ? 'text' : 'password'} // Si showPassword est vrai, montrer le texte, sinon masquer
            placeholder="Mot de passe"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            borderColor="gray.300"
            focusBorderColor="blue.400"
            _hover={{ borderColor: 'gray.400' }}
            disabled={isLoading}
          />
          <InputRightElement width="3rem">
            <Button h="1.75rem" size="sm" onClick={() => setShowPassword(!showPassword)}>
              {showPassword ? <AiFillEye /> : <AiFillEyeInvisible />}
            </Button>
          </InputRightElement>
        </InputGroup>

        <Input
          type="text"
          placeholder="Certicode (Code personnel)"
          value={certicode}
          onChange={(e) => setCerticode(e.target.value)}
          mb={3}
          maxLength={6} // Limite à 6 chiffres pour le Certicode
          pattern="\d{6}" // Accepter uniquement 6 chiffres
          inputMode="numeric" // Ouvre le clavier numérique sur mobile
          onKeyPress={(e) => {
            if (!/[0-9]/.test(e.key)) {
              e.preventDefault(); // Empêcher l'entrée de lettres ou autres caractères non numériques
            }
          }}
          borderColor="gray.300"
          focusBorderColor="blue.400" // Couleur de focus moderne
          _hover={{ borderColor: 'gray.400' }} // Effet au survol
          disabled={isLoading} // Désactiver le champ en mode chargement
        />

        <Button 
          type="submit" 
          colorScheme="blue" 
          width="100%" 
          bgGradient="linear(to-r, blue.400, cyan.400)" // Dégradé sur le bouton
          _hover={{ bgGradient: 'linear(to-r, blue.500, cyan.500)' }} // Effet de survol
          size="lg"
          disabled={isLoading}
        >
          {isLoading ? <Spinner size="sm" /> : 'Se connecter'}
        </Button>

        <Text mt={4} textAlign="center">
          <Link to="/forgot-password-user" style={{ color: 'blue' }}>
            Mot de passe oublié ?
          </Link>
        </Text>
      </Box>
    </Box>
  );
}

export default LoginPage;
