// src/pages/EmailModelConfirmationSentPage.js
import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

const EmailModelConfirmationSentPage = () => {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading as="h2" size="xl" mb={6}>
       Votre profil de modèle est presque actif ! 📧
      </Heading>
      <Text fontSize="lg" color="gray.600">
       Il vous suffit de confirmer votre inscription via l’e-mail que nous vous avons envoyé.
      </Text>
    </Box>
  );
};

export default EmailModelConfirmationSentPage;
