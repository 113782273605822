import React, { useEffect, useState, useCallback } from 'react';
import '../styles/InventoryPage.css';

const apiUrl = process.env.REACT_APP_API_URL; // Assure-toi que cette ligne est bien présente

function InventoryPage() {
  const [inventory, setInventory] = useState([]);
  const [isCleaning, setIsCleaning] = useState(false); // Verrouillage pour le nettoyage

  const fetchInventory = async () => {
    try {
      const username = localStorage.getItem('username');

      if (!username) {
        console.error("Nom d'utilisateur non trouvé dans le stockage local.");
        return;
      }

      const response = await fetch(`${apiUrl}/api/inventory?username=${username}`);

      if (!response.ok) {
        throw new Error("Erreur lors de la récupération de l'inventaire.");
      }

      const data = await response.json();
      setInventory(data.inventory);
    } catch (error) {
      console.error("Erreur lors de la récupération de l'inventaire :", error);
    }
  };

  const deleteInventoryItem = async (filename) => {
    try {
      const username = localStorage.getItem('username');
      if (!username) {
        console.error("Nom d'utilisateur non trouvé dans le stockage local.");
        return;
      }

      const response = await fetch(`${apiUrl}/api/users/${username}/inventory/${filename}`, {
        method: 'DELETE',
      });

      if (!response.ok) {
        throw new Error("Erreur lors de la suppression de l'élément.");
      }

      setInventory((prevInventory) =>
        prevInventory.filter((item) => item.filename !== filename)
      );

      console.log(`Élément supprimé : ${filename}`);
    } catch (error) {
      console.error("Erreur lors de la suppression de l'élément :", error);
    }
  };

  const cleanExpiredAndOrphanFiles = useCallback(async () => {
    if (isCleaning) return; // Éviter les exécutions multiples
    setIsCleaning(true);

    try {
      console.log("Démarrage du nettoyage des fichiers expirés et orphelins...");

      // Nettoyer les fichiers expirés (du backend)
      const username = localStorage.getItem('username');
      if (!username) {
        console.error("Nom d'utilisateur non trouvé pour le nettoyage.");
        setIsCleaning(false);
        return;
      }

      const expiredCleanResponse = await fetch(`${apiUrl}/api/inventory/clean-expired`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ username }),
      });

      if (!expiredCleanResponse.ok) {
        throw new Error("Erreur lors du nettoyage des fichiers expirés côté backend.");
      }

      console.log("Nettoyage des fichiers expirés terminé côté backend.");

      // Étape locale : Supprimer les fichiers orphelins côté client
      const inventoryFiles = inventory.map((item) => item.filename); // Fichiers référencés dans l'inventaire
      const inventoryFolderResponse = await fetch(`${apiUrl}/api/uploads/inventory-files`);
      const inventoryFolderFiles = await inventoryFolderResponse.json(); // Liste des fichiers dans le dossier

      const orphanFiles = inventoryFolderFiles.filter(
        (file) => !inventoryFiles.includes(file) // Trouver les fichiers non référencés
      );

      const deleteOrphanPromises = orphanFiles.map(async (file) => {
        try {
          const response = await fetch(`${apiUrl}/api/uploads/inventory/${file}`, {
            method: 'DELETE',
          });

          if (response.ok) {
            console.log(`Fichier orphelin supprimé : ${file}`);
          } else {
            console.error(`Erreur lors de la suppression du fichier orphelin : ${file}`);
          }
        } catch (error) {
          console.error(`Erreur lors de la suppression du fichier orphelin : ${file}`, error);
        }
      });

      await Promise.allSettled(deleteOrphanPromises);
      console.log("Nettoyage des fichiers orphelins terminé.");
    } catch (error) {
      console.error("Erreur lors du nettoyage des fichiers expirés et orphelins :", error);
    } finally {
      setIsCleaning(false); // Libérer le verrou
    }
  }, [isCleaning, inventory]);

  useEffect(() => {
    fetchInventory();
    const cleanupInterval = setInterval(cleanExpiredAndOrphanFiles, 5 * 60 * 1000); // Nettoyage toutes les 5 minutes

    return () => clearInterval(cleanupInterval); // Nettoyer l'intervalle au démontage
  }, [cleanExpiredAndOrphanFiles]); // Ajout des dépendances pour éviter les warnings

  return (
    <div className="inventory-page">
      <h1>Votre Inventaire</h1>
      <div className="inventory-items">
        {inventory.length > 0 ? (
          inventory.map((item, index) => (
            <div key={index} className="inventory-item">
              {/* Vérifier si l'élément est une image ou une vidéo */}
              {item.type === 'image' ? (
              <img
                src={`${apiUrl}/uploads/inventory/${item.filename}`} // Chemin mis à jour pour les images
                alt={`inventory-item-${index}`}
                className="inventory-image"
              />
            ) : item.type === 'video' ? (
              <video
                  src={`${apiUrl}/uploads/inventory/${item.filename}`} // Chemin mis à jour pour les vidéos
                  controls
                  preload="metadata"
                  width="300"
                  height="200"
                  className="inventory-video"
                  />
                ) : (
                  <p>Type inconnu</p>
              )}
              <p>Rareté: {item.rarity}</p>
              <p>
                Expire à :{' '}
                {item.expiresAt
                  ? new Date(item.expiresAt).toLocaleString()
                  : 'Aucune expiration'}
              </p>
              <button
                onClick={() => deleteInventoryItem(item.filename)}
                className="delete-button"
              >
                Supprimer
              </button>
            </div>
          ))
        ) : (
          <p>Votre inventaire est vide.</p>
        )}
      </div>
    </div>
  );
}

export default InventoryPage;