import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  Box,
  Button,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Text,
  VStack,
  FormHelperText,
  useToast, // Importation du hook useToast
} from '@chakra-ui/react';
import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons';

const apiUrl = process.env.REACT_APP_API_URL; // Récupérer l'URL de base depuis la variable d'environnement


function ResetPasswordPageUser() {
  const { token } = useParams(); // Récupérer le token depuis l'URL
  const [newPassword, setNewPassword] = useState('');
  const [newCerticode, setNewCerticode] = useState('');
  const [showPassword, setShowPassword] = useState(false); // Pour afficher ou masquer le mot de passe
  const navigate = useNavigate();
  const toast = useToast(); // Initialiser le hook pour les toasts

  // État pour validation dynamique du mot de passe
  const [passwordValidation, setPasswordValidation] = useState({
    length: false,
    lowercase: false,
    uppercase: false,
    number: false,
    specialChar: false,
  });

  // Fonction de validation dynamique du mot de passe
  const validatePassword = (password) => {
    const length = password.length >= 8 && password.length <= 64;
    const lowercase = /[a-z]/.test(password);
    const uppercase = /[A-Z]/.test(password);
    const number = /\d/.test(password);
    const specialChar = /[!@#$%^&*(),.?":{}|<>]/.test(password);

    setPasswordValidation({ length, lowercase, uppercase, number, specialChar });
    setNewPassword(password);
  };

  // Gérer la réinitialisation du mot de passe et certicode
  const handleResetPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/api/user/reset-password-user/${token}`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ newPassword, newCerticode }),
      });

      const data = await response.json();

      if (response.ok) {
        toast({
          title: 'Réinitialisation réussie.',
          description: 'Votre mot de passe et certicode ont été réinitialisés avec succès.',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
        navigate('/login'); // Rediriger vers la page de connexion
      } else {
        toast({
          title: 'Erreur de réinitialisation.',
          description: data.message || 'Erreur lors de la réinitialisation.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      }
    } catch (error) {
      console.error('Erreur lors de la réinitialisation:', error);
      toast({
        title: 'Erreur serveur.',
        description: 'Une erreur s\'est produite lors de la réinitialisation.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  // Validation du certicode pour s'assurer qu'il est numérique
  const handleCerticodeChange = (e) => {
    const value = e.target.value;
    if (/^\d*$/.test(value)) {
      setNewCerticode(value); // N'accepte que les chiffres
    }
  };

  return (
    <Box p={6}>
      <VStack spacing={5} align="stretch">
        <Text fontSize="2xl" fontWeight="bold" textAlign="center">
          Réinitialisation du mot de passe et certicode
        </Text>

        <form onSubmit={handleResetPassword}>
          {/* Nouveau mot de passe */}
          <FormControl id="new-password" isRequired>
            <FormLabel>Nouveau mot de passe</FormLabel>
            <InputGroup>
              <Input
                type={showPassword ? 'text' : 'password'}
                value={newPassword}
                onChange={(e) => validatePassword(e.target.value)}
              />
              <InputRightElement h={'full'}>
                <Button
                  variant={'ghost'}
                  onClick={() => setShowPassword((show) => !show)}
                >
                  {showPassword ? <ViewOffIcon /> : <ViewIcon />}
                </Button>
              </InputRightElement>
            </InputGroup>
            <FormHelperText>Le mot de passe doit répondre aux critères suivants :</FormHelperText>
            <Box mt={2}>
              <Text color={passwordValidation.length ? 'green.500' : 'red.500'}>
                • 8 à 64 caractères
              </Text>
              <Text color={passwordValidation.lowercase ? 'green.500' : 'red.500'}>
                • Inclure une lettre minuscule
              </Text>
              <Text color={passwordValidation.uppercase ? 'green.500' : 'red.500'}>
                • Inclure une lettre majuscule
              </Text>
              <Text color={passwordValidation.number ? 'green.500' : 'red.500'}>
                • Inclure un chiffre
              </Text>
              <Text color={passwordValidation.specialChar ? 'green.500' : 'red.500'}>
                • Inclure un caractère spécial
              </Text>
            </Box>
          </FormControl>

          {/* Nouveau certicode */}
          <FormControl id="new-certicode" mt={4} isRequired>
            <FormLabel>Nouveau certicode</FormLabel>
            <Input
              type="text"
              maxLength="6"
              value={newCerticode}
              onChange={handleCerticodeChange}
            />
            <FormHelperText>6 chiffres requis</FormHelperText>
          </FormControl>

          {/* Bouton de réinitialisation */}
          <Button colorScheme="green" type="submit" mt={6} isFullWidth>
            Réinitialiser
          </Button>
        </form>
      </VStack>
    </Box>
  );
}

export default ResetPasswordPageUser;
