import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useToast, Button, Input, Box, Heading, Text } from '@chakra-ui/react'; // Importer useToast et les composants Chakra

function ForgotPasswordPage() {
  const [email, setEmail] = useState('');
  const navigate = useNavigate(); // Pour la redirection après la soumission
  const toast = useToast(); // Initialiser useToast

  const apiUrl = process.env.REACT_APP_API_URL; // Utiliser la variable d'environnement pour l'API


  const handleForgotPassword = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${apiUrl}/api/model/forgot-password`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();

      if (response.ok) {
        toast({
          title: 'Lien de réinitialisation envoyé.',
          description: 'Un lien a été envoyé à votre email pour réinitialiser votre mot de passe et certicode.',
          status: 'success',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
        // Rediriger vers la page de connexion des modèles
        navigate('/model/login');
        } else {
        // Gérer les erreurs spécifiques
        if (response.status === 429) {
          // Cas limiteur (trop de requêtes)
          toast({
            title: 'Trop de demandes.',
            description: data.message || 'Vous avez atteint la limite de tentatives. Veuillez réessayer plus tard.',
            status: 'error',
            duration: 5000,
            isClosable: true,
            position: 'top',
          });
        } else {
        // Autres erreurs (email non trouvé, etc.)
        toast({
          title: 'Erreur.',
          description: data.message || 'Une erreur est survenue lors de la demande de réinitialisation.',
          status: 'error',
          duration: 5000,
          isClosable: true,
          position: 'top',
        });
      }
    }
    } catch (error) {
      console.error('Erreur lors de la demande de réinitialisation:', error);

      toast({
        title: 'Erreur serveur.',
        description: 'Une erreur s\'est produite lors de la demande. Veuillez réessayer.',
        status: 'error',
        duration: 5000,
        isClosable: true,
        position: 'top',
      });
    }
  };

  return (
    <Box 
      display="flex" 
      justifyContent="center" 
      alignItems="center" 
      minHeight="100vh"
      bgGradient="linear(to-r, #FF7E5F, #FEB47B)" // Fond dégradé rose/orangé
    >
      <Box
        as="form"
        onSubmit={handleForgotPassword}
        p="6"
        rounded="lg"
        boxShadow="0 0 20px rgba(255, 105, 180, 0.5)" // Ombre néon rose/orangé initiale
        bg="white"
        width="400px"
        transition="all 0.3s ease" // Transition fluide pour l'effet de survol
        _hover={{
          boxShadow: '0 0 30px rgba(255, 105, 180, 0.8)', // Bordure néon rose/orangé agrandie au survol
          transform: 'scale(1.05)', // Légère mise à l'échelle au survol
        }}
      >
        <Heading as="h2" mb={6} textAlign="center" color="gray.700" fontWeight="bold">
          Mot de passe oublié
        </Heading>

        <Text mb={4} textAlign="center" color="gray.500">
          Entrez votre adresse email pour recevoir un lien de réinitialisation de mot de passe et certicode.
        </Text>

        <Input
          type="email"
          placeholder="Entrez votre email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          mb={4}
          borderColor="gray.300"
          focusBorderColor="pink.400" // Couleur de focus moderne (rose)
          _hover={{ borderColor: 'gray.400' }} // Effet au survol
        />

        <Button 
          type="submit" 
          colorScheme="pink" 
          width="100%" 
          bgGradient="linear(to-r, pink.400, orange.400)" // Dégradé rose/orangé sur le bouton
          _hover={{ bgGradient: 'linear(to-r, pink.500, orange.500)' }} // Effet de survol
          size="lg"
        >
          Envoyer le lien de réinitialisation
        </Button>
      </Box>
    </Box>
  );
}

export default ForgotPasswordPage;
