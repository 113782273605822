import React, { useEffect, useState, useRef } from 'react';
import { useParams } from 'react-router-dom';
import '../styles/BoxDetailPage.css'; // Utilisation exclusive du fichier CSS
import { FaInfoCircle, FaTimesCircle } from 'react-icons/fa'; // Ajout de FaTimesCircle
import Modal from 'react-modal';
import { Box, Image, Text, Button, VStack, HStack, Badge, Spinner, useToast } from '@chakra-ui/react';
import { motion } from 'framer-motion';
import { useCallback } from 'react';
import Particles from "../components/Particles";


Modal.setAppElement('#root');

function BoxDetailPage() {
  const { id } = useParams();
  const [box, setBox] = useState({});
  const [result, setResult] = useState(null);
  const [isRolling, setIsRolling] = useState(false);
  const [rollImages, setRollImages] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [hoveredImageIndex, setHoveredImageIndex] = useState(null);
  const [hovered, setHovered] = useState(false);
  const [backgroundBlurred, setBackgroundBlurred] = useState(false);
  const [closingOverlay, setClosingOverlay] = useState(false); // État temporaire pour empêcher une réouverture
  const [showFullDescription, setShowFullDescription] = useState(false); // Nouvel état pour "Voir plus"
  const [loading, setLoading] = useState(true); // Ajout de l'état de chargement
  const [userKeysBalance, setUserKeysBalance] = useState(0); // État pour stocker le solde des clés
  const [isRollLocked, setIsRollLocked] = useState(false);
  const [resetAnimationState, setResetAnimationState] = useState(false); // Ajout de `resetAnimationState`
  const [isButtonDisabled, setIsButtonDisabled] = useState(false); // État pour désactiver le bouton
  const rollTrackRef = useRef(null);
  const toast = useToast();
  const audioRef = useRef(null);

  // Détection si l'utilisateur est sur mobile
  // Cette constante sera utilisée pour désactiver la lecture automatique des vidéos sur les appareils mobiles
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


  const apiUrl = process.env.REACT_APP_API_URL; // Utilisation de la variable d'environnement
  const userId = localStorage.getItem('userId'); // Récupérer l'ID utilisateur


  const reloadBoxContent = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/admin/boxes/${id}/view`);
      const updatedBox = await response.json();
      setBox(updatedBox); // Met à jour les détails de la box
  
      const newRollImages = updatedBox.mediaFiles.map(file => {
        return file.filename.endsWith('.mp4') || file.filename.endsWith('.webm')
          ? `${apiUrl}/uploads/animations/${file.filename}`
          : `${apiUrl}/uploads/floutees/${file.filename}`;
      });
      setRollImages(newRollImages); // Met à jour les images pour le rouleau
    } catch (error) {
      console.error('Erreur lors du rechargement du contenu de la box :', error);
    }
  };


  // Fonction pour réinitialiser tous les états avec un délai
  const resetAllStates = () => {
    setRollImages([]);
    setResult(null);
    setBackgroundBlurred(false);
    setIsRolling(false); // S'assure que l'animation est arrêtée

    // Nettoyer les vidéos chargées dans l'animation
  const videos = document.querySelectorAll('.roll-media');
  videos.forEach(video => {
    video.pause();
    video.removeAttribute('src'); // Retire la source pour libérer la mémoire
    video.load();
  });

  // Réinitialiser les états des photos si nécessaire
  const images = document.querySelectorAll('.roll-image');
  images.forEach(img => {
    img.src = ''; // Efface temporairement la source pour forcer un rechargement
  });

    if (audioRef.current) {
      audioRef.current.pause();
      audioRef.current.currentTime = 0;
    }

    // Stoppe et retire la source de toutes les vidéos pour éviter une relecture
    const videoElements = document.querySelectorAll('video');
    if (videoElements.length > 0) {
      videoElements.forEach(video => {
      video.pause();
      video.removeAttribute('src');
      video.load();
    });
    }
  };

  // Fonction pour fermer l'image gagnante avec un délai et éviter la réouverture
  const handleCloseImage = () => {
    setClosingOverlay(true); // Activer l'état temporaire pour éviter la réouverture
    setIsButtonDisabled(true); // Désactive le bouton immédiatement

    // Utiliser un délai pour s'assurer que tout est bien réinitialisé
    setTimeout(() => {
      resetAllStates();
      setIsModalOpen(false);
      setClosingOverlay(false); // Désactiver l'état temporaire après la fermeture
      setResetAnimationState(false); // Fin du cycle de réinitialisation de l'animation

      // Vérification pour les caisses mixtes et le type de média gagné
    const isMixedBox =
    box.mediaFiles.some((file) => file.filename.endsWith('.mp4') || file.filename.endsWith('.webm')) &&
    box.mediaFiles.some((file) => !file.filename.endsWith('.mp4') && !file.filename.endsWith('.webm'));

  const isPhoto = result && !result.endsWith('.mp4') && !result.endsWith('.webm');

  if (isMixedBox && isPhoto) {
    // Rafraîchir complètement la page pour les caisses mixtes avec des photos gagnées
    window.location.reload();
  } else if (result && (result.endsWith('.mp4') || result.endsWith('.webm'))) {
    // Actualisation de la page si une vidéo est gagnée
    window.location.reload();
  } else {
    // Recharge uniquement les contenus pour d'autres cas
    reloadBoxContent();
  }
        // Réactiver le bouton après une courte période si ce n'est pas une vidéo
        const delay = result && (result.endsWith('.mp4') || result.endsWith('.webm')) ? 8000 : 5000; // 8 secondes pour vidéos, 5 secondes pour photos
      setTimeout(() => {
        setIsButtonDisabled(false); // Réactive le bouton sans actualisation de la page
      }, delay);
        }, 300); // Délai pour permettre une réinitialisation complète
      };


  
  // Effet pour quitter le plein écran si `result` est remis à `null`
useEffect(() => {
  if (result === null && document.fullscreenElement) {
    try {
      document.exitFullscreen();
    } catch (error) {
      console.error("Erreur lors de la sortie du mode plein écran :", error);
    }
  }
}, [result]);

   // Utilisation de l'effet pour relancer l'animation après la fermeture de l'overlay
  useEffect(() => {
    if (resetAnimationState) {
      setRollImages((prevImages) => [...prevImages]); // Force la réinitialisation des vidéos
      setIsRolling(true); // Redémarre l'animation
      setResetAnimationState(false); // Empêche la réinitialisation continue
    }
  }, [resetAnimationState]);

  // Charger les images d'animation avec une clé unique pour forcer le rechargement
useEffect(() => {
  const fetchRollImages = async () => {
    if (box.mediaFiles) {
      const newRollImages = box.mediaFiles.map((file, index) => {
        const src = file.filename.endsWith('.mp4') || file.filename.endsWith('.webm')
          ? `${apiUrl}/uploads/animations/${file.filename}`
          : `${apiUrl}/uploads/floutees/${file.filename}`;
        return { src, key: `${file.filename}-${index}-${Date.now()}` }; // Clé unique pour chaque image
      });
      setRollImages(newRollImages);
    }
  };
  fetchRollImages();
 }, [box, apiUrl]); // Ajoutez `box` et `apiUrl` comme dépendances

// Nettoie toute vidéo en plein écran lorsque `result` est remis à `null` pour éviter les pop-ups fantômes
useEffect(() => {
  if (result === null && document.fullscreenElement) {
    try {
      document.exitFullscreen();
    } catch (error) {
      console.error("Erreur lors de la sortie du mode plein écran :", error);
    }
  }
}, [result]);


  // Code pour désactiver le clic droit sur toute la page
useEffect(() => {
  const disableRightClick = (event) => {
    event.preventDefault();
  };

  document.addEventListener('contextmenu', disableRightClick);

  // Nettoyage pour supprimer l'écouteur quand le composant est démonté
  return () => {
    document.removeEventListener('contextmenu', disableRightClick);
  };
}, []);
  

// 1. Déclaration de la fonction `fetchKeysBalance` pour récupérer le solde des clés
const fetchKeysBalance = useCallback(async () => {
  try {
    const response = await fetch(`${apiUrl}/api/user/keys-balance/${userId}`, {
      method: 'GET',
      headers: { 'Content-Type': 'application/json' },
    });
    const data = await response.json();
    setUserKeysBalance(data.keys_balance); // Mise à jour du solde des clés
  } catch (error) {
    console.error('Erreur lors de la récupération du solde des clés :', error);
  }
}, [apiUrl, userId]);


// Fonction pour enregistrer l'achat dans MongoDB
const registerPurchase = async (userId, boxId, boxPrice) => {
  try {
    const response = await fetch(`${apiUrl}/api/purchase/create`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId,
        boxId,
        amountTTC: boxPrice, // Prix TTC de la box
      }),
    });

    const data = await response.json();
    if (response.ok) {
      console.log("Achat enregistré avec succès :", data.purchase);
    } else {
      console.error("Erreur lors de l'enregistrement de l'achat.");
    }
  } catch (error) {
    console.error("Erreur lors de l'enregistrement de l'achat :", error);
  }
};

// 2. Fonction pour ouvrir une caisse et déduire les clés
const handleOpenBox = async (boxId, boxPrice) => {
  if (closingOverlay) return; // Empêche l'ouverture si on est en train de fermer l'overlay
  resetAllStates(); // Assurez-vous que tout est prêt pour un nouveau départ
  try {
    const response = await fetch(`${apiUrl}/api/box/open-box`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        userId,
        boxId,
        boxPrice,
      }),
    });

    const data = await response.json();
    if (response.ok) {
      toast({
        title: "Caisse ouverte avec succès!",
        description: "Félicitations, vous avez ouvert une caisse !",
        status: "success",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
       // 3. Appel de `fetchKeysBalance` pour rafraîchir le solde après ouverture de la caisse
      fetchKeysBalance(); 

      // Appel de la fonction pour enregistrer l'achat dans MongoDB
      await registerPurchase(userId, boxId, boxPrice); // Nouvelle fonction ici

    } else {
      toast({
        title: "Erreur",
        description: data.message || "Une erreur est survenue lors de l'ouverture de la caisse.",
        status: "error",
        duration: 3000,
        isClosable: true,
        position: "top",
      });
    }
  } catch (error) {
    console.error('Erreur lors de l\'ouverture de la caisse:', error);
    toast({
      title: "Erreur serveur",
      description: "Impossible d'ouvrir la caisse pour le moment.",
      status: "error",
      duration: 3000,
      isClosable: true,
      position: "top",
    });
  }
};


// Désactiver le bouton si l'utilisateur n'a pas assez de clés ou si la box n'a pas de prix
const isDisabled = userKeysBalance < (box ? box.price : 0);


// Désactiver le bouton pendant 5 secondes après le chargement de la page
useEffect(() => {
  setIsButtonDisabled(true);
  const delayTimeout = setTimeout(() => {
    setIsButtonDisabled(false); // Réactiver le bouton après 5 secondes
  }, 3000);

  return () => clearTimeout(delayTimeout); // Nettoyage
}, []);

// 4. Utilisation du `useEffect` pour charger la box et les clés lors de la première exécution
useEffect(() => {
  const fetchBox = async () => {
    try {
      const response = await fetch(`${apiUrl}/api/admin/boxes/${id}/view`);
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des détails de la caisse.');
      }
      const data = await response.json();
      setBox(data); // Met à jour les détails de la box


      // Vérifiez si la box contient des médias mixtes
      const isMixedBox = data.mediaFiles.some(file => file.filename.endsWith('.mp4') || file.filename.endsWith('.webm')) &&
                         data.mediaFiles.some(file => !file.filename.endsWith('.mp4') && !file.filename.endsWith('.webm'));
      if (isMixedBox) {
        console.log('Cette box contient des médias mixtes (photos et vidéos).');
      }

      // Initialise rollImages après avoir récupéré les données de la box icicicicicicic!!!!!!!!!!!!
      const newRollImages = data.mediaFiles.map(file => {
        if (file.filename.endsWith('.mp4') || file.filename.endsWith('.webm')) {
          return `${apiUrl}/uploads/animations/${file.filename}`;
        }
        return `${apiUrl}/uploads/floutees/${file.filename}`;
      });
      setRollImages(newRollImages); // Met à jour rollImages avec les chemins appropriés

    } catch (error) {
      console.error('Erreur lors de la récupération des détails de la caisse:', error);
    } finally {
      setLoading(false);
    }
  };

  fetchBox();
  // Appel de `fetchKeysBalance` pour récupérer les clés initialement
  if (userId) {
    fetchKeysBalance();
  }
}, [id, userId, apiUrl, fetchKeysBalance]); // Assurez-vous que le tableau de dépendances est complet

// **AJOUTEZ LE NOUVEAU useEffect ICI**
useEffect(() => {
  const preloadVideos = async () => {
    if (box.mediaFiles && box.mediaFiles.length > 0) {
      console.log('Préchargement des vidéos...');
      
      const videoElements = box.mediaFiles
        .filter(file => file.filename.endsWith('.mp4') || file.filename.endsWith('.webm'))
        .map(file => {
          const video = document.createElement('video');
          video.src = `${apiUrl}/uploads/animations/${file.filename}`;
          return new Promise((resolve) => {
            video.onloadeddata = () => resolve();
            video.onerror = () => resolve();
          });
        });

      await Promise.all(videoElements);
      console.log('Toutes les vidéos sont prêtes');
    }
  };

  preloadVideos();
}, [box.mediaFiles, apiUrl]); // Dépendance sur les fichiers média

if (loading) {
  return <Spinner size="xl" color="teal.500" />;
}


// Filtrer les médias pour ne garder que les images dans la grille de prévisualisation
const fixedPreviewImages = box.mediaFiles
? box.mediaFiles.filter(media => !media.filename.endsWith('.mp4') && !media.filename.endsWith('.webm'))
: [];

  const handleOpenBoxStats = async () => {
    if (isRolling || closingOverlay) return; // Évite de lancer une nouvelle animation si une est déjà en cours ou si l'overlay est en cours de fermeture
    if (!box || !box.mediaFiles || box.mediaFiles.length === 0) {
      console.error('Aucun média disponible pour ouvrir la caisse.');
      return;
    }

    try {
      // Appel à l'API pour incrémenter le nombre d'ouvertures de la caisse
      await incrementOpenCount(box._id); // Incrémenter le compteur ici

      if (audioRef.current) {
        await audioRef.current.play();
      }
    } catch (error) {
      console.error('Erreur lors de la lecture du son:', error);
    }

    // Démarrer l'animation en activant le verrouillage
    setIsRolling(true);
    setIsRollLocked(true); // Bloquer les interactions pendant que le rouleau tourne

  

    const chosenResult = selectResultBasedOnRarity(box.mediaFiles);
    if (!chosenResult) {
      console.error('Aucun média avec une rareté valide trouvé.');
      setIsRolling(false);
      setIsRollLocked(false); // Libérer les interactions même si aucun résultat n'a été choisi
      return;
    }

    setResult(chosenResult.filename);
    setRollImages(generateRollImages(box.mediaFiles, chosenResult.filename));

    await updateInventory(chosenResult);

    setTimeout(() => {
      setIsRolling(false);
      setIsRollLocked(false); // Libérer les interactions lorsque l'animation est terminée
      setBackgroundBlurred(true);
      // Ajout d'un délai pour réactiver le bouton
  setTimeout(() => {
    setIsButtonDisabled(false);
  }, 3000); // Ajout de 3 secondes après l'animation
  }, 8100);

    setTimeout(() => {
      toast({
        title: "Félicitations !",
        description: "Vous avez gagné un média ! Téléchargez-le avant qu'il ne disparaisse.",
        status: "success",
        duration: 3500,
        isClosable: true,
        position: "top",
      });
    }, 8100);
  };

  // Fonction pour incrémenter le nombre d'ouvertures via l'API
  const incrementOpenCount = async (boxId) => {
    try {
      const response = await fetch(`${apiUrl}/api/admin/open-box/${boxId}`, {
        method: 'POST',
      });

      if (!response.ok) {
        throw new Error('Erreur lors de l\'incrémentation du compteur d\'ouvertures.');
      }

      const data = await response.json();
      console.log('Nombre d\'ouvertures mis à jour avec succès :', data.openCount);
    } catch (error) {
      console.error('Erreur lors de l\'incrémentation du compteur d\'ouvertures :', error);
    }
  };

  const updateInventory = async (media) => {
    try {
      const username = localStorage.getItem('username');
      const response = await fetch(`${apiUrl}/api/users/${username}/inventory`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          filename: media.filename,
          rarity: media.rarity,
          boxTitle: box.title,
          boxId: box._id,
        }),
      });

      if (!response.ok) {
        throw new Error('Erreur lors de la mise à jour de l\'inventaire.');
      }

      console.log('Inventaire mis à jour avec succès.');
    } catch (error) {
      console.error('Erreur lors de la mise à jour de l\'inventaire:', error);
    }
  };

  const selectResultBasedOnRarity = (mediaFiles) => {
    const rarityRates = {
      Élémentaire: 79.92,
      Singulier: 15.98,
      Intrigant: 3.2,
      Captivant: 0.64,
      Mythique: 0.26,
    };

    const validMedia = mediaFiles.filter((media) => media.rarity in rarityRates);
    const weightedMedia = [];

    validMedia.forEach((media) => {
      const rate = rarityRates[media.rarity];
      for (let i = 0; i < rate * 100; i++) {
        weightedMedia.push(media);
      }
    });

    if (weightedMedia.length === 0) {
      console.error('Aucun média avec une rareté valide trouvé.');
      return null;
    }

    const randomIndex = Math.floor(Math.random() * weightedMedia.length);
    return weightedMedia[randomIndex];
  };

  const getBlurredImagePath = (filename) => {
    return `${apiUrl}/uploads/floutees/${filename}`;
  };

  const rollContent = box.boxType === 'video' ? box.originalVideos : box.mediaFiles;

  const getAnimationImagePath = (filename) => {
    if (box.boxType === 'video' && (filename.endsWith('.mp4') || filename.endsWith('.webm'))) {
      // Chemin vers la vidéo si c'est un fichier vidéo
      return `${apiUrl}/uploads/animations/${filename}`;
    } else {
      // Chemin vers l'image si c'est une image
      return `${apiUrl}/uploads/floutees/${filename}`;
    }
  };

  const generateRollImages = (mediaFiles, result) => {
    let rollImages = [];
    const totalNeeded = 33;
    const randomMediaFiles = mediaFiles.sort(() => 0.5 - Math.random());  // Mélange aléatoire des images disponibles
    const repeatCount = Math.ceil(totalNeeded / rollContent.length);

    // Ajouter les images aléatoires
  rollImages = randomMediaFiles.map((media) => getAnimationImagePath(media.filename));

    // Ajouter les médias (images et vidéos) aléatoires au rouleau
      randomMediaFiles.forEach((media) => {
      rollImages.push(getAnimationImagePath(media.filename));
    });

    for (let i = 0; i < repeatCount; i++) {
      rollImages = [...rollImages, ...rollImages];
    }

    rollImages = rollImages.slice(0, totalNeeded); // Limiter à 31 images

    const fakeResultsCount = 3;
    for (let i = 0; fakeResultsCount > i; i++) {
      const randomFake = mediaFiles[Math.floor(Math.random() * mediaFiles.length)].filename;
      rollImages.push(getAnimationImagePath(randomFake));
    }

    const indexToInsertWinner = rollImages.length - fakeResultsCount - 1;
    rollImages[indexToInsertWinner] = `${apiUrl}/uploads/${result}`;

    return rollImages;
  };

  const handleImageClick = (e) => {
    e.preventDefault();
  };

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  
  const rarityRates = {
    Élémentaire: 79.92,
    Singulier: 15.98,
    Intrigant: 3.2,
    Captivant: 0.64,
    Mythique: 0.26,
  };

  const getRarityBadge = (rarity) => {
    const colors = {
      Élémentaire: 'gray',
      Singulier: 'blue',
      Intrigant: 'purple',
      Captivant: 'orange',
      Mythique: 'pink',
    };
    return (
      <Badge colorScheme={colors[rarity] || 'gray'}>{rarity}</Badge>
    );
  };

  if (!box) return <div>Chargement...</div>;

  return (
    <Box className={`box-detail ${backgroundBlurred ? 'blurred-background' : ''}`} maxW="800px" mx="auto" textAlign="center">
      <Text fontSize="3xl" fontWeight="bold">{box.title}</Text>
      <FaInfoCircle
        className="info-icon"
        onClick={openModal}
        style={{ cursor: 'pointer', marginLeft: '10px' }}
        />
      {/* Vérifie si la boîte est bien définie et si elle possède un prix */}
      {!box || !box.price ? (
        <Text fontSize="2xl">Chargement des détails de la caisse...</Text>
      ) : (
        <>
          <Box 
            className="box-image-container" 
            onMouseEnter={() => setHovered(true)} 
            onMouseLeave={() => setHovered(false)}
            my={4}
          >
            <Image
              height="150px"
              width="200px"
              objectFit="cover"
              src={`${apiUrl}/uploads/${box.thumbnail}`}
              alt={`${box.title} thumbnail`}
              mx="auto"
            />
            {hovered && (
              <div className="box-info-hover">
                <p>
                  {showFullDescription ? box.description : `${box.description.slice(0, 50)}...`} 
                  <span 
                    onClick={() => setShowFullDescription(!showFullDescription)} 
                    className="more"
                    style={{ color: '#FFD700', cursor: 'pointer' }}
                  >
                    {showFullDescription ? ' Voir moins' : ' Voir plus'}
                  </span>
                </p>
                <p>Prix: €{box.price}</p>
                <p>Catégorie: {box.category}</p>
              </div>
            )}
          </Box>

          {!isRolling ? (
  // Si isRolling est faux, afficher le bouton animé
  <motion.div style={{ position: "relative", display: "inline-block" }}>
    <Particles />
    <motion.button
      onClick={() => {
        if (!isDisabled) {
          handleOpenBox(box._id, box.price);
          handleOpenBoxStats();
        } else {
          toast({
            title: "Solde de clés insuffisant",
            description: "Vous n'avez pas assez de clés pour ouvrir cette caisse.",
            status: "error",
            duration: 3000,
            isClosable: true,
            position: "top",
          });
        }
      }}
      disabled={isButtonDisabled || isDisabled}
      initial={{ scale: 1 }}
      animate={{
        boxShadow: [
          "0 0 15px rgba(255, 255, 255, 0.7)",
          "0 0 25px rgba(255, 255, 255, 0.9)",
          "0 0 35px rgba(255, 215, 0, 1)",
          "0 0 25px rgba(255, 255, 255, 0.9)",
          "0 0 15px rgba(255, 255, 255, 0.7)",
        ],
      }}
      whileHover={{
        scale: 1.1, // Agrandit légèrement lors du survol (PC uniquement)
        boxShadow: "0 0 40px rgba(255, 215, 0, 1)", // Accentuation de l'ombre
      }}
      whileTap={{
        scale: 0.95, // Réduit légèrement lors du clic
        boxShadow: "0 0 50px rgba(255, 215, 0, 1)", // Éclat intense temporaire
      }}
      transition={{
        duration: 1.5,
        repeat: Infinity,
        repeatType: "reverse", // Répétition fluide en boucle
      }}
      style={{
        backgroundColor: "#3182CE",
        color: "white",
        padding: "12px 24px",
        fontSize: "16px",
        border: "none",
        borderRadius: "8px",
        cursor: isButtonDisabled || isDisabled ? "not-allowed" : "pointer",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
        opacity: isButtonDisabled || isDisabled ? 0.6 : 1,
      }}
    >
      {isRolling ? "Ouverture en cours..." : "Ouvrir cette caisse"}
    </motion.button>
  </motion.div>
) : (
  <Spinner size="xl" color="white" />
)}

          <audio ref={audioRef} src="/sounds/open-box-sound.mp3" preload="auto" />

          <Box mt={8}>
            <Text fontSize="lg" mb={4}> Médias dans cette caisse :</Text>

            {/* Première ligne de 6 images */}
            <HStack spacing={4}>
            {fixedPreviewImages.slice(0, 6).map((media, index) => (
                <motion.div
                  key={index}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                  onMouseEnter={() => setHoveredImageIndex(index)}
                  onMouseLeave={() => setHoveredImageIndex(null)}
                  style={{ position: 'relative' }}
                >
                  <Image // Images vignettes original 130 120
                    height="100px"
                    width="90px"
                    objectFit="cover"
                    borderRadius="md"
                    src={getBlurredImagePath(media.filename)}
                    alt={`aperçu-${index}`}
                    onContextMenu={handleImageClick}
                    onClick={handleImageClick}
                    draggable="false"
                  />
                  {hoveredImageIndex === index && (
                    <motion.div
                      initial={{ opacity: 0 }}
                      animate={{ opacity: 0.85 }}
                      exit={{ opacity: 0 }}
                      style={{
                        position: 'absolute',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)',
                        color: 'white',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        borderRadius: '8px',
                        padding: '6px',
                        fontSize: '11px',
                        textAlign: 'center',
                        boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
                      }}
                    >
                      <VStack spacing={1}>
                        {getRarityBadge(media.rarity)}
                        <Text>{rarityRates[media.rarity]}% loot chance</Text>
                      </VStack>
                    </motion.div>
                  )}
                </motion.div>
              ))}
            </HStack>

            {/* Deuxième ligne de 6 images */}
  <HStack spacing={4} mt={4}>
      {fixedPreviewImages.slice(6, 12).map((media, index) => (
      <motion.div
        key={index + 6} // Décalage de l'index pour la deuxième ligne
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onMouseEnter={() => setHoveredImageIndex(index + 6)}
        onMouseLeave={() => setHoveredImageIndex(null)}
        style={{ position: 'relative' }}
      >
        <Image // images vignettes original 130 120 
          height="100px"
          width="90px"
          objectFit="cover"
          borderRadius="md"
          src={getBlurredImagePath(media.filename)}
          alt={`aperçu-${index + 6}`}
          onContextMenu={handleImageClick}
          onClick={handleImageClick}
          draggable="false"
        />
        {hoveredImageIndex === index + 6 && (
          <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 0.85 }}
            exit={{ opacity: 0 }}
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
              backgroundColor: 'rgba(0, 0, 0, 0.5)',
              color: 'white',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              borderRadius: '8px',
              padding: '6px',
              fontSize: '11px',
              textAlign: 'center',
              boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.15)',
            }}
          >
            <VStack spacing={1}>
              {getRarityBadge(media.rarity)}
              <Text>{rarityRates[media.rarity]}% loot chance</Text>
            </VStack>
          </motion.div>
        )}
      </motion.div>
    ))}
  </HStack>
          </Box>

           {isRolling && (
           <Box className="roll-animation" mt={8}>
            <Box className="roll-track" ref={rollTrackRef}>
              {rollImages.map((media, index) => {
                return media.endsWith('.mp4') || media.endsWith('.webm') ? (
                 <video
                  key={index}
                  src={media}  // Utilisation directe de `media`
                  className="roll-media uniform-roll-item" // Ajoutez la classe uniforme
                  poster={`${media}#t=0.1`} // Affiche la première image comme miniature 
                  preload="metadata" // Charge juste les métadonnées sans lecture
                  muted
                  controls={false}
                  autoPlay={!isMobile} // Désactive autoPlay si l'utilisateur est sur mobile
                  loop={!isMobile} // Désactive la boucle pour les appareils mobiles
                  playsInline // Évite le plein écran forcé sur iOS
                  onClick={isRollLocked ? null : handleImageClick} // Désactive le clic si verrouillé
                  onContextMenu={isRollLocked ? null : handleImageClick} // Désactive le clic droit si verrouillé
                  draggable="false"
                   />
                  ) : (
                  <Image
                    key={index}
                    src={media}
                    alt={`roll-${index}`}
                    className="roll-image uniform-roll-item" // Ajoutez la classe uniforme
                    onClick={isRollLocked ? null : handleImageClick} // Désactive le clic si verrouillé
                    onContextMenu={isRollLocked ? null : handleImageClick} // Désactive le clic droit si verrouillé
                    draggable="false"
                  />
                  )
                })}
              </Box>
              <Box className="fixed-cursor"></Box>
            </Box>
          )}

          {result && !isRolling && (
             <Box 
             className="result-overlay"
             style={{
               position: 'fixed', // Fixe l'image gagnante pour qu'elle recouvre tout l'écran
               top: 0,
               left: 0,
               width: '100vw',
               height: '100vh',
               backgroundColor: 'rgba(0, 0, 0, 0.8)', // Fond semi-transparent
               display: 'flex',
               justifyContent: 'center',
               alignItems: 'center',
               zIndex: 1000, // Assure que l'image est au premier plan
             }}
             onClick={(e) => e.stopPropagation()} // Empêche la propagation pour éviter la fermeture accidentelle
           >
              <motion.div
                initial={{ scale: 0 }}
                animate={{ scale: 1 }}
                transition={{ duration: 0.5 }}
                style={{ 
                  position: 'relative',
                  display: 'flex', 
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center', 
                  textAlign: 'center'  }}
              >
                <Text fontSize="2xl" color="white" mb={4}>Vous avez obtenu :</Text>

                {result.endsWith('.mp4') || result.endsWith('.webm') ? (
                  <video
                  src={`${apiUrl}/uploads/${result}`}
                  alt="result"
                  className="result-media"
                  style={{
                  maxWidth: '90%',
                  maxHeight: '90vh',
                  }}
                  autoPlay
                  controls
                  />
                 ) : (
                
                <Image
                  src={`${apiUrl}/uploads/${result}`} 
                  alt="result"
                  className="result-image"
                  style={{
                    maxWidth: '90%', // Ajuste pour couvrir une grande partie de l'écran
                    height: '90',
                    maxHeight: '90vh', // Limite la hauteur pour qu’elle s’adapte à l’écran
                  }}
                  mx="auto"
                  draggable="true"
                  onContextMenu={(e) => e.stopPropagation()}
                />
                 )}
                
                {/* Icône de téléchargement */}
                <a href={`${apiUrl}/uploads/${result}`} download>
                  <motion.div
                    whileHover={{ scale: 1.2 }}
                    style={{
                      position: 'absolute',
                      bottom: '10px',
                      right: '10px',
                      backgroundColor: '#000',
                      padding: '10px',
                      borderRadius: '50%',
                      zIndex: 10,
                    }}
                  >
                    <svg 
                      xmlns="http://www.w3.org/2000/svg" 
                      fill="white" 
                      viewBox="0 0 24 24" 
                      width="24" 
                      height="24"
                    >
                      <path d="M5 20h14v-2H5v2zm7-18l-7 7h4v6h6v-6h4l-7-7z"/>
                    </svg>
                  </motion.div>
                </a>

                {/* Icône de fermeture pour l'image gagnante */}
                <FaTimesCircle
                  onClick={handleCloseImage}
                  size={40}
                  style={{
                    position: 'absolute',
                    top: '10px',
                    right: '10px',
                    color: 'white',
                    cursor: 'pointer',
                  }}
                />
              </motion.div>
            </Box>
          )}

          <Modal
            isOpen={isModalOpen}
            onRequestClose={closeModal}
            contentLabel="Explication des Probabilités"
            className="modal"
            overlayClassName="overlay"
          >
            <Text fontSize="2xl" mb={4}>Comment Fonctionnent les Probabilités dans Nos Caisses</Text>
            <Text color="black">Ton texte explicatif ici</Text>
            <Button onClick={closeModal} colorScheme="red" mt={4}>
              Fermer
            </Button>
          </Modal>
        </>
      )}
    </Box>
  );
}

export default BoxDetailPage;