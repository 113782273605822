
import React, { useState, useEffect, useCallback, useRef } from 'react';
import { 
  Box, 
  Button, 
  Input, 
  Select, 
  Textarea, 
  VStack, 
  HStack, 
  Heading, 
  Image, 
  SimpleGrid, 
  FormControl, 
  FormLabel, 
  List, 
  ListItem, 
  useToast,
  Text 
} from '@chakra-ui/react';
import axios from 'axios'; // Import Axios pour récupérer les modèles

function AdminPage() {
  const [models, setModels] = useState([]); // Stocker les modèles récupérés
  const [selectedModel, setSelectedModel] = useState(''); // Stocker l'ID du modèle sélectionné
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [price, setPrice] = useState('');
  const [category, setCategory] = useState('Nouveautés');
  const [mediaFiles, setMediaFiles] = useState([]);
  const mediaFilesRef = useRef(null);
  const thumbnailRef = useRef(null);
  const originalVideosRef = useRef(null);
  const [mediaRarities, setMediaRarities] = useState([]);
  const [thumbnail, setThumbnail] = useState(null);
  const [boxes, setBoxes] = useState([]);
  const [storyTitle, setStoryTitle] = useState('');
  const [storyCreator, setStoryCreator] = useState('');
  const [storyAvatar, setStoryAvatar] = useState(null);
  const [storyMedia, setStoryMedia] = useState(null);
  const [stories, setStories] = useState([]);
  const toast = useToast();
  const [priceHT, setPriceHT] = useState(''); // Stocker le prix HT
  const [commission, setCommission] = useState(''); // Stocker la commission
  const [modelRevenue, setModelRevenue] = useState(''); // Stocker le revenu du modèle


  const [boxType, setBoxType] = useState('photo'); // "photo" ou "video"
  const [originalVideos, setOriginalVideos] = useState([]); // Pour les fichiers vidéo
  const [videoRarities, setVideoRarities] = useState([]); // Pour les catégories des vidéos
  const [videoDropRates, setVideoDropRates] = useState([]); // Pour les taux de drop des vidéos

  // --- Début des fonctionnalités pour les carrousels ---
  const [carouselImages, setCarouselImages] = useState([]); // Liste des images sélectionnées pour le carrousel
  const [carouselData, setCarouselData] = useState([]); // Liste des carrousels récupérés


  const fetchCarouselImages = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL || 'https://onlybox.fans/api'; // Vérifie bien que cette variable est correctement définie
      const response = await fetch(`${apiUrl}/api/carousel`); // Utiliser /api/carousel au lieu de /api/admin/carousel
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des images du carousel');
      }
      const data = await response.json();
      console.log(data); // <-- Ajoute ceci pour vérifier la structure des données
      setCarouselData(Array.isArray(data) ? data : []);
    } catch (error) {
      console.error('Erreur lors de la récupération des images du carousel:', error);
      toast({
        title: 'Erreur',
        description: 'Erreur lors de la récupération des images du carousel.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]);


  // Fonction pour gérer l'upload d'images
  const handleCarouselImageUpload = (e) => {
    const files = Array.from(e.target.files);
    const supportedFormats = ['image/webp', 'image/jpeg', 'image/png'];
    if (files.length > 10) {
      toast({
        title: 'Erreur',
        description: 'Vous ne pouvez pas uploader plus de 10 images.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    // Vérification des formats des fichiers
  const invalidFiles = files.filter(file => !supportedFormats.includes(file.type));
  if (invalidFiles.length > 0) {
    toast({
      title: 'Erreur',
      description: 'Seuls les formats WebP, JPEG et PNG sont acceptés.',
      status: 'error',
      duration: 5000,
      isClosable: true,
    });
    return;
  }
    setCarouselImages(files);
  };

  // Fonction pour soumettre le carrousel
  const handleCarouselSubmit = async (e) => {
    e.preventDefault();

    if (carouselImages.length === 0) {
      toast({
        title: 'Erreur',
        description: 'Veuillez sélectionner au moins un fichier image.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const formData = new FormData();
    
    // Ajouter chaque fichier d'image dans le formulaire
    carouselImages.forEach((image) => {
     formData.append('images', image); // Utilisez un champ 'images' pour plusieurs fichiers
    });

    try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await fetch(`${apiUrl}/api/admin/carousel`, {
            method: 'POST',
            body: formData,
          });
          const result = await response.json();
          console.log('Images du carousel créées :', result);
          fetchCarouselImages(); // Rafraîchir la liste des images
          setCarouselImages([]); // Réinitialiser le champ des fichiers
          } catch (error) {
          console.error('Erreur lors de la création de l\'image du carousel :', error);
           toast({
           title: 'Erreur',
           description: 'Erreur lors de la création des images du carousel.',
           status: 'error',
           duration: 5000,
           isClosable: true,
         });
       }
     };


  const handleDeleteCarouselImage = async (id) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      await fetch(`${apiUrl}/api/admin/carousel/${id}`, {
        method: 'DELETE',
      });

      fetchCarouselImages();
      toast({
        title: 'Succès',
        description: 'Image du carousel supprimée avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la suppression de l\'image du carousel:', error);
      toast({
        title: 'Erreur',
        description: 'Erreur lors de la suppression de l\'image du carousel.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };


  const fetchBoxes = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/boxes`);
      const data = await response.json();
      setBoxes(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des caisses:', error);
      toast({
        title: 'Erreur',
        description: 'Erreur lors de la récupération des caisses.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]);
  
  const fetchStories = useCallback(async () => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/admin/stories`);
      if (!response.ok) {
        throw new Error('Erreur lors de la récupération des stories');
      }
      const data = await response.json();
      setStories(data);
    } catch (error) {
      console.error('Erreur lors de la récupération des stories:', error);
      toast({
        title: 'Erreur',
        description: 'Erreur lors de la récupération des stories.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  }, [toast]);

  // Fonction pour calculer le prix HT, la commission, et le revenu du modèle
  const calculateValues = (priceTTC) => {
  if (priceTTC && !isNaN(priceTTC)) {
    const ht = (priceTTC / 1.2).toFixed(2); // Calcul du prix HT (enlever 20% de TVA) //Calcul du prix HT avec deux chiffres après la virgule

    let comm = 0;
    let revenue = 0;

    // Si un modèle est sélectionné, calculer la commission et le revenu
    if (selectedModel) {
      comm = (ht * 0.40).toFixed(2); // Commission 40%
      revenue = (ht - comm).toFixed(2); // Revenu du modèle
    } else {
      // Si aucun modèle, tout le HT vous revient
      comm = 0;
      revenue = ht;
    }

    // Mettre à jour les états
    setPriceHT(ht);
    setCommission(comm);
    setModelRevenue(revenue);
  } else {
    // Réinitialiser si le prix n'est pas valide
    setPriceHT('');
    setCommission('');
    setModelRevenue('');
  }
};

  // Récupérer les modèles depuis l'API
  useEffect(() => {
    const fetchModels = async () => {
      try {
        const apiUrl = process.env.REACT_APP_API_URL;
        const response = await axios.get(`${apiUrl}/api/admin/models`);
        setModels(response.data); // Stocker les modèles dans le state
      } catch (error) {
        console.error("Erreur lors de la récupération des modèles :", error);
        toast({
          title: 'Erreur',
          description: 'Erreur lors de la récupération des modèles.',
          status: 'error',
          duration: 5000,
          isClosable: true,
        });
      }
    };
    fetchModels(); // Appeler la fonction pour récupérer les modèles
  }, [toast]);

  useEffect(() => {
    const fetchData = async () => {
      await Promise.all([
        fetchBoxes(),
        fetchStories(),
        fetchCarouselImages(),
      ]);
    };
    fetchData();
  }, [fetchBoxes, fetchStories, fetchCarouselImages]);

  // Définir les taux de drop en fonction de la rareté sélectionnée
  const rarityRates = {
    Élémentaire: 79.92,
    Singulier: 15.98,
    Intrigant: 3.2,
    Captivant: 0.64,
    Mythique: 0.26,
  };


  const handleMediaUpload = (e) => {
    const files = Array.from(e.target.files);
    setMediaFiles(files);
    setMediaRarities(files.map(() => 'Élémentaire'));
  };


  const handleVideoUpload = (e) => {
    const files = Array.from(e.target.files);
    
    // Définir les vidéos sélectionnées
    setOriginalVideos(files);
  
    // Initialiser la rareté à "Élémentaire" pour chaque vidéo
    const defaultRarities = files.map(() => 'Élémentaire');
    setVideoRarities(defaultRarities);
  
    // Définir les taux de drop basés sur la rareté "Élémentaire" par défaut
    const defaultDropRates = defaultRarities.map(rarity => rarityRates[rarity]);
    setVideoDropRates(defaultDropRates);
  };

  const handleRarityChange = (index, newRarity) => {
    const updatedRarities = [...mediaRarities];
    updatedRarities[index] = newRarity;
    setMediaRarities(updatedRarities);
  };


  // Ajoute tes nouvelles fonctions ici
const handleRarityChangeForVideo = (index, newRarity) => {
  const updatedRarities = [...videoRarities];
  updatedRarities[index] = newRarity;
  setVideoRarities(updatedRarities);


// Mettre à jour le taux de drop automatiquement en fonction de la rareté
const updatedDropRates = [...videoDropRates];
updatedDropRates[index] = rarityRates[newRarity] || 0; // Par défaut à 0 si la rareté est non définie
setVideoDropRates(updatedDropRates);
};

  const handleThumbnailUpload = (e) => {
    setThumbnail(e.target.files[0]);
  };

  const handleSubmitBox = async (e) => {
    e.preventDefault();
    
    const formData = new FormData();

    // Ajout du modèle sélectionné si disponible
    if (selectedModel) {
      console.log("ID du modèle sélectionné :", selectedModel);
      formData.append('createdByModelId', selectedModel); // Associe l'ID du modèle sélectionné à la caisse
    }
    
    // Calcul du prix HT
  const priceHT = parseFloat((price / 1.2).toFixed(2)); // Prix HT arrondi à deux chiffres
  
  let commission = 0;
  let modelRevenue = 0;

  // Si un modèle est sélectionné, on calcule la commission et le revenu du modèle
  if (selectedModel) {
    commission = parseFloat((priceHT * 0.40).toFixed(2)); // 40% de commission
    modelRevenue = parseFloat((priceHT - commission).toFixed(2)); // Revenu du modèle après commission
  }
    
    formData.append('title', title);
    formData.append('description', description);
    formData.append('price', price); // Prix TTC
    formData.append('priceHT', priceHT); // Prix HT arrondi
    
    // Si un modèle est sélectionné, ajouter commission et revenue du modèle
    if (selectedModel) {
       formData.append('commission', commission); // Commission arrondie
       formData.append('modelRevenue', modelRevenue); // Revenu modèle arrondi
      }
       
       formData.append('category', category);
       console.log("Type de caisse sélectionné :", boxType);
       formData.append('boxType', boxType); // Indique le type de caisse (photo ou vidéo)
       

       // Si la caisse est de type "photo", utilise le champ Médias pour l'aperçu et le rouleau
       if (boxType === 'photo') {
      mediaFiles.forEach((file, index) => {
      formData.append('mediaFiles', file);
      formData.append('mediaRarities', mediaRarities[index]);
    });
    }

    // Si la caisse est de type "vidéo", utilise Médias pour les vignettes et Vidéos Originales pour le rouleau
    else if (boxType === 'video') {
    // Ajoute les captures d'écran en tant que vignettes dans Médias
    mediaFiles.forEach((file, index) => {
      formData.append('mediaFiles', file); // Images de capture d’écran
      formData.append('mediaRarities', mediaRarities[index]);
    });

    originalVideos.forEach((videoFile, index) => {
      formData.append('originalVideos', videoFile); // Vidéos pour le rouleau
      formData.append('videoRarities', videoRarities[index]);

    
    // Vérifier si des vidéos originales sont fournies, puis les ajouter avec des taux de drop basés sur la rareté
    // Ajout des vidéos originales si elles sont fournies

    const rarityRates = {
      Élémentaire: 79.92,
      Singulier: 15.98,
      Intrigant: 3.2,
      Captivant: 0.64,
      Mythique: 0.26,
    };

    
      // Appliquez automatiquement le taux de drop selon la rareté de la vidéo
      const dropRate = rarityRates[videoRarities[index]] || 0;
      formData.append('videoDropRates', dropRate);

    });
  } 
  
    if (thumbnail) {
    formData.append('thumbnail', thumbnail);
    }

    // Ajoutez le code ici pour voir le contenu de formData avant d'envoyer la requête
  for (let pair of formData.entries()) {
    console.log(pair[0] + ', ' + pair[1]);
  }

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/admin/boxes`, {
      method: 'POST',
      body: formData,
      });
      const result = await response.json();
      console.log('Caisse créée avec modèle :', result); // Log les données renvoyées par le serveur

      console.log('Caisse créée:', result);
      // Réinitialiser les champs après soumission
      setOriginalVideos([]);
      setVideoRarities([]);
      setVideoDropRates([]);
      setTitle('');
      setDescription('');
      setPrice('');
      setCategory('Nouveautés');
      setMediaFiles([]);
      setThumbnail(null);
      setMediaRarities([]);
      setSelectedModel(''); // Réinitialiser la sélection du modèle
      setBoxType('photo'); // Réinitialiser le type de caisse

       // Utilisez les refs pour effacer les champs de fichier dans le DOM
    if (mediaFilesRef.current) {
      mediaFilesRef.current.value = '';
    }
    if (thumbnailRef.current) {
      thumbnailRef.current.value = '';
    }
    if (originalVideosRef.current) {
      originalVideosRef.current.value = ''; // Vider les vidéos originales
    }

      fetchBoxes(); // Rafraîchissez la liste des caisses
    } catch (error) {
      console.error('Erreur lors de la création de la caisse:', error);
      toast({
        title: 'Erreur',
        description: 'Erreur lors de la création de la caisse.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };


  const handleDeleteBox = async (id) => {
    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      await fetch(`${apiUrl}/api/admin/boxes/${id}`, {
      method: 'DELETE',
      });

      fetchBoxes();
      toast({
        title: 'Succès',
        description: 'Caisse supprimée avec succès.',
        status: 'success',
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error('Erreur lors de la suppression de la caisse:', error);
      toast({
        title: 'Erreur',
        description: 'Erreur lors de la suppression de la caisse.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const handleStorySubmit = async (e) => {
    e.preventDefault();

    const formData = new FormData();

    
    formData.append('title', storyTitle);
    formData.append('creator', storyCreator);
    formData.append('avatar', storyAvatar);
    formData.append('media', storyMedia);

    try {
      const apiUrl = process.env.REACT_APP_API_URL;
      const response = await fetch(`${apiUrl}/api/admin/stories`, {
      method: 'POST',
      body: formData,
      });

      const result = await response.json();
      console.log('Story créée:', result);
      fetchStories();
      setStoryTitle('');
      setStoryCreator('');
      setStoryAvatar(null);
      setStoryMedia(null);
    } catch (error) {
      console.error('Erreur lors de la création de la story:', error);
      toast({
        title: 'Erreur',
        description: 'Erreur lors de la création de la story.',
        status: 'error',
        duration: 5000,
        isClosable: true,
      });
    }
  };

  return (
    <Box p={5}>
      <Heading mb={5}>Admin - Gestion des Caisses</Heading>

      {/* Formulaire de création de caisse */}
      <VStack as="form" spacing={4} onSubmit={handleSubmitBox}>
        
        {/* Sélectionner un modèle (non obligatoire) */}
        <FormControl>
          <FormLabel>Sélectionner un modèle (optionnel)</FormLabel>
          <Select value={selectedModel} onChange={(e) => setSelectedModel(e.target.value)}>
            <option value="">-- Choisissez un modèle --</option>
            {models.map((model) => (
              <option key={model._id} value={model._id}>{model.username}</option>
            ))}
          </Select>
        </FormControl>

        <FormControl isRequired>
        <FormLabel>Type de Caisse</FormLabel>
        <Select value={boxType} onChange={(e) => setBoxType(e.target.value)}>
        <option value="photo">Photo</option>
        <option value="video">Vidéo</option>
        </Select>
        </FormControl>

        <FormControl isRequired>
          <FormLabel>Titre</FormLabel>
          <Input
            type="text"
            placeholder="Titre"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Description</FormLabel>
          <Textarea
            placeholder="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Prix TTC</FormLabel>
          <Input
            type="number"
            placeholder="Prix TTC"
            value={price}
            onChange={(e) => {setPrice(e.target.value);
            calculateValues(e.target.value); // Appel à la fonction de calcul
          }}
          />
         </FormControl>

         {/* Affichage des valeurs calculées */}
         {price && (
          <Box mt={4}>
          <Text><strong>Prix HT :</strong> {priceHT} €</Text>
          {/* Si un modèle est sélectionné, afficher la commission et le revenu */}
          {selectedModel ? (
            <>
          <Text><strong>Commission (40%) :</strong> {commission} €</Text>
          <Text><strong>Revenu du modèle :</strong> {modelRevenue} €</Text>
           </>
           ) : (
            <Text><strong>Revenu total (sans modèle) :</strong> {modelRevenue} €</Text>
           )}
          </Box>
           )}
        <FormControl>
          <FormLabel>Catégorie</FormLabel>
          <Select value={category} onChange={(e) => setCategory(e.target.value)}>
            <option value="Nouveautés">Nouveautés</option>
            <option value="Bestseller">Bestseller</option>
            <option value="Spécial">Spécial</option>
            <option value="En création">En création</option>
            <option value="Prochainement">Prochainement</option> {/* Nouvelle option ajoutée */}
          </Select>
        </FormControl>
        <FormControl isRequired>
         <FormLabel>Médias</FormLabel>
         <Input
        type="file"
        multiple
        onChange={handleMediaUpload}
        ref={mediaFilesRef} // Ajout du ref
        />
        </FormControl>
        {mediaFiles.map((file, index) => (
          <HStack key={index} spacing={3}>
            <span>{file.name}</span>
            <Select
              value={mediaRarities[index]}
              onChange={(e) => handleRarityChange(index, e.target.value)}
            >
              <option value="Élémentaire">Élémentaire</option>
              <option value="Singulier">Singulier</option>
              <option value="Intrigant">Intrigant</option>
              <option value="Captivant">Captivant</option>
              <option value="Mythique">Mythique</option>
            </Select>
          </HStack>
        ))}


<FormControl>
  <FormLabel>Vidéos Originales (Optionnel)</FormLabel>
  <Input
    type="file"
    multiple
    accept="video/mp4, video/webm"
    onChange={handleVideoUpload} // Utilise la fonction handleVideoUpload
    ref={originalVideosRef} // Ajout du ref ici
  />
</FormControl>
{originalVideos.length > 0 && originalVideos.map((file, index) => (
  <HStack key={index} spacing={3}>
    <span>{file.name}</span>
    <Select
      value={videoRarities[index]}
      onChange={(e) => handleRarityChangeForVideo(index, e.target.value)}
    >
      <option value="Élémentaire">Élémentaire</option>
      <option value="Singulier">Singulier</option>
      <option value="Intrigant">Intrigant</option>
      <option value="Captivant">Captivant</option>
      <option value="Mythique">Mythique</option>
    </Select>
    
   {/* Affichage automatique du taux de drop */}
   <Text>{videoDropRates[index]}%</Text>
</HStack>
))}      

        <FormControl isRequired>
          <FormLabel>Miniature</FormLabel>
          <Input
            type="file"
            onChange={handleThumbnailUpload}
            accept="image/*"
            ref={thumbnailRef} // Ajout du ref
          />
        </FormControl>
        <Button type="submit" colorScheme="teal">Créer Caisse</Button>
      </VStack>

      {/* Liste des caisses créées */}
      <Heading mt={10} mb={5} size="md">Caisses Créées</Heading>
      <List spacing={4}>
        {boxes.map((box) => (
          <ListItem key={box._id}>
            <Box p={3} shadow="md" borderWidth="1px" borderRadius="md">
              <Heading size="sm">{box.title}</Heading>
              <Box mt={2}>
                <strong>Médias:</strong>
                <SimpleGrid columns={[2, null, 4]} spacing={4} mt={2}>
                  {box.mediaFiles.map((file) => (
                    <Image
                      key={file.filename}
                      src={`${process.env.REACT_APP_API_URL}/uploads/${file.filename}`}
                      alt="Media"
                      boxSize="100px"
                      objectFit="cover"
                      borderRadius="md"
                    />
                  ))}
                </SimpleGrid>
              </Box>
              <Button colorScheme="red" mt={3} onClick={() => handleDeleteBox(box._id)}>Supprimer</Button>
            </Box>
          </ListItem>
        ))}
      </List>

      <Heading mt={10} mb={5}>Créer un Carrousel</Heading>
<VStack as="form" spacing={4} onSubmit={handleCarouselSubmit}>
  <FormControl isRequired>
    <FormLabel>Images du Carrousel (1 à 10)</FormLabel>
    <Input
      type="file"
      multiple
      onChange={handleCarouselImageUpload}
      accept="image/webp, image/jpeg, image/png"
    />
  </FormControl>

  {/* Visualiser les images sélectionnées avant soumission */}
  <VStack>
    {carouselImages.length > 0 && (
      <SimpleGrid columns={[2, null, 4]} spacing={4}>
        {carouselImages.map((image, index) => (
          <Box key={index} position="relative">
            <Image
              src={typeof image === 'string'
                ? `${process.env.REACT_APP_API_URL}/uploads/${image}` // Si c'est une chaîne venant du serveur
                : URL.createObjectURL(image) // Sinon, prévisualisation locale pour les fichiers
              }
              alt={`Carousel Image ${index + 1}`}
              boxSize="100px"
              objectFit="cover"
              borderRadius="md"
            />
            {/* Bouton pour supprimer l'image */}
            <Button
              size="xs"
              colorScheme="red"
              position="absolute"
              top="0"
              right="0"
              onClick={() => handleDeleteCarouselImage(index)} // Fonction pour supprimer l'image
            >
              X
            </Button>
          </Box>
        ))}
      </SimpleGrid>
    )}
  </VStack>
  {/* Bouton pour créer le carrousel */}
  <Button type="submit" colorScheme="teal">Créer Carrousel</Button>
</VStack>

  {/* Liste des carrousels créés */}
  <Heading mt={10} mb={5}>Carrousels Créés</Heading>
      <List spacing={4}>
        {carouselData.length > 0 ? (
          carouselData.map((carousel) => (
            <ListItem key={carousel._id}>
              <Box p={3} shadow="md" borderWidth="1px" borderRadius="md">
                <Heading size="sm">Carrousel {carousel._id}</Heading>
                <SimpleGrid columns={[2, null, 4]} spacing={4} mt={2}>
                  {carousel.images.map((image, index) => (
                    <Image
                      key={index}
                      src={`${process.env.REACT_APP_API_URL}/uploads/${image}`} 
                      alt={`Carousel Image ${index + 1}`}
                      boxSize="100px"
                      objectFit="cover"
                      borderRadius="md"
                    />
                  ))}
                </SimpleGrid>
                <Button
                  colorScheme="red"
                  mt={3}
                  onClick={() => handleDeleteCarouselImage(carousel._id)}
                >
                  Supprimer Carrousel
                </Button>
              </Box>
            </ListItem>
          ))
        ) : (
          <Text>Aucun carrousel trouvé.</Text>
        )}
      </List>
      {/* Formulaire de création de story */}
      <Heading mt={10} mb={5}>Créer une Story</Heading>
      <VStack as="form" spacing={4} onSubmit={handleStorySubmit}>
        <FormControl isRequired>
          <FormLabel>Titre de la Story</FormLabel>
          <Input
            type="text"
            placeholder="Titre de la Story"
            value={storyTitle}
            onChange={(e) => setStoryTitle(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Créateur de la Story</FormLabel>
          <Input
            type="text"
            placeholder="Nom du créateur"
            value={storyCreator}
            onChange={(e) => setStoryCreator(e.target.value)}
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Avatar du Créateur</FormLabel>
          <Input
            type="file"
            onChange={(e) => setStoryAvatar(e.target.files[0])}
            accept="image/*"
          />
        </FormControl>
        <FormControl isRequired>
          <FormLabel>Média de la Story</FormLabel>
          <Input
            type="file"
            onChange={(e) => setStoryMedia(e.target.files[0])}
            accept="image/*"
          />
        </FormControl>
        <Button type="submit" colorScheme="teal">Créer Story</Button>
      </VStack>

      {/* Liste des stories créées */}
      <Heading mt={10} mb={5} size="md">Stories Créées</Heading>
      <List spacing={4}>
        {stories.map((story) => (
          <ListItem key={story._id}>
            <Box p={3} shadow="md" borderWidth="1px" borderRadius="md">
              <Heading size="sm">{story.title}</Heading>
              <Box mt={2}>
                <Image
                  src={`${process.env.REACT_APP_API_URL}/uploads/${story.avatar}`}
                  alt="Avatar"
                  boxSize="50px"
                  objectFit="cover"
                  borderRadius="full"
                />
                <Text mt={2}><strong>Créateur:</strong> {story.creator}</Text>
              </Box>
            </Box>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default AdminPage;