import React, { useState, useEffect } from 'react';
import { loadStripe } from '@stripe/stripe-js';
import { Elements, CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { Box, Text, Input, Heading, Button, useToast } from '@chakra-ui/react';

// Lazy loading de la bibliothèque Stripe
const stripePromise = loadStripe('pk_test_51Q6YxjHtN7Es0vfXbQWK4Xvk6cklQqJWbYfiCLWzc6kVuhfWDBZ3ePJ5de05yKDNoAeLgIA8zfKgjDzkaigJwjC1008xeBaEky');

function CheckoutForm({ totalPrice, keyCount }) {
  const stripe = useStripe();
  const elements = useElements();
  const toast = useToast();
  const apiUrl = process.env.REACT_APP_API_URL;

  useEffect(() => {
    console.log("Stripe chargé :", stripe);
    console.log("Elements chargé :", elements);
  }, [stripe, elements]);

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (!stripe || !elements) {
      toast({
        title: "Erreur",
        description: "Le système de paiement n'est pas prêt. Veuillez réessayer plus tard.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
      return;
    }

    const cardElement = elements.getElement(CardElement);
    if (!cardElement) {
      console.error("CardElement n'est pas disponible.");
      return;
    }

    try {
      console.log("Création du PaymentIntent...");
      const response = await fetch(`${apiUrl}/api/stripe/create-payment-intent`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'X-Content-Type-Options': 'nosniff',
          'X-Frame-Options': 'DENY',
          'Strict-Transport-Security': 'max-age=63072000; includeSubDomains; preload',
        },
        credentials: 'include', // Inclus les cookies si nécessaire
        body: JSON.stringify({ amount: totalPrice * 100 }), // Stripe utilise les centimes
      });

      if (!response.ok) {
        const errorText = await response.text();
        console.error("Erreur lors de la création du PaymentIntent :", errorText);
        toast({
          title: "Erreur",
          description: "Impossible de créer le paiement. Veuillez réessayer.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
        return;
      }

      const { clientSecret } = await response.json();

      if (!clientSecret) {
        console.error("ClientSecret manquant.");
        return;
      }

      console.log("Confirmation du paiement...");
      const result = await stripe.confirmCardPayment(clientSecret, {
        payment_method: { card: cardElement },
      });

      if (result.error) {
        console.error("Erreur Stripe :", result.error.message);
        toast({
          title: "Erreur de paiement",
          description: result.error.message || "Un problème est survenu.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      } else if (result.paymentIntent?.status === 'succeeded') {
        console.log("Paiement réussi :", result.paymentIntent);
        toast({
          title: "Paiement réussi !",
          description: `Vous avez acheté ${keyCount} clé(s).`,
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        await updateUserKeys();
        // Redirection vers la homepage après un délai
        setTimeout(() => {
          window.location.href = "/";
        }, 5000); // Redirection après 5 secondes (durée du toast)
      }
    } catch (error) {
      console.error("Erreur inattendue :", error);
      toast({
        title: "Erreur",
        description: "Une erreur inattendue s'est produite. Veuillez réessayer.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };

  const updateUserKeys = async () => {
    try {
      const userId = localStorage.getItem('userId');
      if (!userId) {
        console.error("Identifiant utilisateur manquant.");
        return;
      }

      const response = await fetch(`${apiUrl}/api/user/credit-keys`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          userId,
          keysPurchased: keyCount,
        }),
      });

      if (response.ok) {
        console.log("Les clés ont été créditées avec succès.");
      } else {
        const errorText = await response.text();
        console.error("Erreur lors du crédit des clés :", errorText);
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour des clés :", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <CardElement />
      <Button mt={4} type="submit" isDisabled={!stripe}>
        Payer {totalPrice.toFixed(2)} €
      </Button>
    </form>
  );
}

function BuyKeys() {
  const [keyCount, setKeyCount] = useState(0);
  const [totalPrice, setTotalPrice] = useState(0);
  const toast = useToast();

  useEffect(() => {
    setTotalPrice(keyCount > 0 ? keyCount : 0);
  }, [keyCount]);

  const handleKeyCountChange = (e) => {
    const count = parseInt(e.target.value, 10);
    setKeyCount(!isNaN(count) && count > 0 ? count : 0);
  };

  return (
    <Box mt={10} p={5} maxW="500px" mx="auto" bg="gray.800" borderRadius="lg">
      <Heading as="h3" size="lg" mb={5} color="white">
        Acheter des clés
      </Heading>
      <Text mb={3} color="white">Nombre de clés :</Text>
      <Input
        type="number"
        min="1"
        value={keyCount}
        onChange={handleKeyCountChange}
        mb={5}
      />
      <Text color="white" mb={3}>Total à payer : {totalPrice.toFixed(2)} €</Text>

      {stripePromise && (
        <Elements stripe={stripePromise}>
          <CheckoutForm totalPrice={totalPrice} keyCount={keyCount} />
        </Elements>
      )}
    </Box>
  );
}

export default BuyKeys;
