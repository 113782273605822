// src/pages/EmailConfirmationSentPage.js
import React from 'react';
import { Box, Heading, Text } from '@chakra-ui/react';

const EmailConfirmationSentPage = () => {
  return (
    <Box textAlign="center" py={10} px={6}>
      <Heading as="h2" size="xl" mb={6}>
       Vous y êtes presque ! 📧
      </Heading>
      <Text fontSize="lg" color="gray.600">
       Consultez vos e-mails pour activer votre compte et débloquer toutes les fonctionnalités.
      </Text>
    </Box>
  );
};

export default EmailConfirmationSentPage;
